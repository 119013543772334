import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { HelpInfo, MediaLibrary } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export function HelpInfoEditorFields(props: FormikProps<HelpInfo>) {

    //const values = props.values as Drug;

    //const isReadOnly = false;

    const handleChange = (html) => {
        props.setFieldValue('helpText', html);
    }

    const handleContactsChange = (html) => {
        props.setFieldValue('helpContacts', html);
    }

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
    /* 
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    return (<Row>
        <Col sm="12" md="12">
            <div className="form-section">

                <h3>Help Page</h3>

                <ReactQuill
                    theme="snow"
                    onChange={handleChange}
                    value={props.values.helpText}
                    modules={modules}
                    formats={formats}
                    bounds={'.app'}
                    placeholder="Edit Text"
                />

                <FormUtils.GroupSetText
                    fieldName="helpLinkText1"
                    title="Link Text 1"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="helpLinkMediaLibraryId1"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="helpLinkUrl1"
                    title="Link URL 1"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="helpLinkDesc1"
                    title="Link Description 1"
                />
                <FormUtils.GroupSetText
                    fieldName="helpLinkText2"
                    title="Link Text 2"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="helpLinkMediaLibraryId2"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="helpLinkUrl2"
                    title="Link URL 2"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="helpLinkDesc2"
                    title="Link Description 2"
                />
                <FormUtils.GroupSetText
                    fieldName="helpLinkText3"
                    title="Link Text 3"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="helpLinkMediaLibraryId3"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="helpLinkUrl3"
                    title="Link URL 3"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="helpLinkDesc3"
                    title="Link Description 3"
                />
                <FormUtils.GroupSetText
                    fieldName="helpLinkText4"
                    title="Link Text 4"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="helpLinkMediaLibraryId4"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="helpLinkUrl4"
                    title="Link URL 4"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="helpLinkDesc4"
                    title="Link Description 4"
                />

                <ReactQuill
                    theme="snow"
                    onChange={handleContactsChange}
                    value={props.values.helpContacts}
                    modules={modules}
                    formats={formats}
                    bounds={'.app'}
                    placeholder="Edit Text"
                />
            </div>
        </Col>
    </Row>);
}

