import * as React from 'react';
import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { AppThunkActionAsync } from "./store/index";
import axios from 'axios';


declare var process: any;

export function clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
}

export function getPromiseFromAction<T, V>(asyncActionCreator: AppThunkActionAsync<T, V>): Promise<V> {
    return (asyncActionCreator as any) as Promise<V>;
}

/**
 * Is server prerendering by Node.js.
 * There can't be any DOM: window, document, etc.
 */
export function isNode(): boolean {
    return typeof process === 'object' && process.versions && !!process.versions.node;
}

export function isObjectEmpty(obj: any): boolean {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const dateFormatString = "dd MMM, yyyy";

export const formatDate = (date: Date) => {

    return date
        ? format(date, dateFormatString)
        : null;
}

export const dateAndTimeFormatString = "dd-MMM-yyyy HH:mm";

export const formatDateAndTime = (date: Date) => {

    return date
        ? format(date, dateAndTimeFormatString)
        : null;
}



export const bytesToFriendly = (bytes?: number) => {
    if (!bytes)
        return null;

    var kb = bytes / 1024;

    if (kb < 1000)
        return `${Math.round(kb * 100) / 100} kb`;

    var mb = kb / 1024;
    return `${Math.round(mb * 100) / 100} mb`;
}

// Our hook
export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {

            //console.log('useDebounce value received', value);

            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {

                //console.log('set debounce value', value);

                setDebouncedValue(value);
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below). 
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {

                //console.log('clearTimeout debounce value');

                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value]
    );

    return debouncedValue;
}

export const downloadFile = (fileUrl: string, fileName: string) => {
    axios({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });
}


export function useTimeout(callback, delay) {
    const timeoutRef = React.useRef<number>();
    const callbackRef = React.useRef(callback);

    // Remember the latest callback:
    //
    // Without this, if you change the callback, when setTimeout kicks in, it
    // will still call your old callback.
    //
    // If you add `callback` to useEffect's deps, it will work fine but the
    // timeout will be reset.

    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Set up the timeout:

    React.useEffect(() => {
        if (typeof delay === 'number') {
            timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);

            // Clear timeout if the components is unmounted or the delay changes:
            return () => window.clearTimeout(timeoutRef.current);
        }
    }, [delay]);

    // In case you want to manually clear the timeout from the consuming component...:
    return timeoutRef;
}