import React, { Component } from 'react';
import { Button, Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import './NavMenu.css';

interface Props {
    //children: any
}

interface State {
    collapsed: boolean
}

export class NavMenu extends Component<Props, State> {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/"><img src={require('../images/shield-logo.png')} alt="ShielD" className="logo" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">

                            <NavItem>
                                <NavLink tag={Link} to="/" active={window.location.pathname === '/'}>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/resources" active={window.location.pathname === '/resources'}>Resources</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/search" active={window.location.pathname === '/search'}>Drug Look Up</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/help" active={window.location.pathname === '/help'}><img src={require('../images/help.png')} alt="Help" className="help-icon" /></NavLink>
                            </NavItem>

                            {/*<UncontrolledDropdown>*/}
                            {/*    <DropdownToggle nav caret>*/}
                            {/*        Dictionaries*/}
                            {/*    </DropdownToggle>*/}
                            {/*    <DropdownMenu>*/}
                            {/*        <NavLink tag={Link} className="dropdown-item" to="/sitesettings/1">Site Settings</NavLink>*/}
                            {/*        <NavLink tag={Link} className="dropdown-item" to="/medialibrary-list">Media Library</NavLink>*/}
                            {/*        <NavLink tag={Link} className="dropdown-item" to="/drugs-list">Drugs</NavLink>*/}
                            {/*        <NavLink tag={Link} className="dropdown-item" to="/task-type-list">Task Types</NavLink>*/}
                            {/*        <NavLink tag={Link} className="dropdown-item" to="/drug-formulations-list">Formulation/Hazard</NavLink>*/}
                            {/*        <NavLink tag={Link} className="dropdown-item" to="/ppe-list">PPEs</NavLink>*/}
                            {/*        <NavLink tag={Link} className="dropdown-item" to="/hd-maps-list">HD Mapping</NavLink>*/}
                            {/*    </DropdownMenu>*/}
                            {/*</UncontrolledDropdown>*/}

                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
