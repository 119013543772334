import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { PersonalProtectiveEquipment } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";


export function PersonalProtectiveEquipmentEditorFields(props: FormikProps<PersonalProtectiveEquipment>) {

    //const values = props.values as Drug;

    //const isReadOnly = false;

    return (<Row>
        <Col sm="6" md="4">
            <div className="form-section">

                <FormUtils.GroupSetText
                    fieldName="title"
                    title="Title"
                    required
                />

                <FormUtils.GroupSetFileUpload
                    fieldName="iconBinary"
                    title="Icon"
                    controlSize="large"
                    names={{
                        fileName: 'fileName',
                        fileBinary: 'letterBinary',
                        fileSize: 'fileSize',
                        fileType: 'fileType'
                    }}
                    required
                />

                <FormUtils.GroupSetCheckbox
                    fieldName="isActive"
                    title="Active"
                />

            </div>

        </Col>
    </Row>);
}

