﻿import * as yup from 'yup';


export class HelpInfo {
    helpId: number;
    helpText: string;

    helpLinkText1: string;
    helpLinkMediaLibraryId1: number;
    helpLinkUrl1: string;
    helpLinkDesc1: string;

    helpLinkText2: string;
    helpLinkMediaLibraryId2: number;
    helpLinkUrl2: string;
    helpLinkDesc2: string;

    helpLinkText3: string;
    helpLinkMediaLibraryId3: number;
    helpLinkUrl3: string;
    helpLinkDesc3: string;

    helpLinkText4: string;
    helpLinkMediaLibraryId4: number;
    helpLinkUrl4: string;
    helpLinkDesc4: string;

    helpContacts: string;

    constructor() {
        this.helpId = 0;
        this.helpText = '';

        this.helpLinkText1 = '';
        this.helpLinkMediaLibraryId1 = 0;
        this.helpLinkUrl1 = '';
        this.helpLinkDesc1 = '';

        this.helpLinkText2 = '';
        this.helpLinkMediaLibraryId2 = 0;
        this.helpLinkUrl2 = '';
        this.helpLinkDesc2 = '';

        this.helpLinkText3 = '';
        this.helpLinkMediaLibraryId3 = 0;
        this.helpLinkUrl3 = '';
        this.helpLinkDesc3 = '';

        this.helpLinkText4 = '';
        this.helpLinkMediaLibraryId4 = 0;
        this.helpLinkUrl4 = '';
        this.helpLinkDesc4 = '';

        this.helpContacts = '';
    }
}

export const ValidationSchema = yup.object().shape({
    helpText: yup.string().required('Required')    
});