import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { SiteSettings, MediaLibrary } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export function SiteSettingsEditorFields(props: FormikProps<SiteSettings>) {

    //const values = props.values as Drug;

    //const isReadOnly = false;

    const handleChange = (html) => {
        props.setFieldValue('homePageText', html);
    }

    const handleLiabilityChange = (html) => {
        props.setFieldValue('liabilityMessage', html);
    }

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
    /* 
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    return (<Row>
        <Col sm="12" md="12">
            <div className="form-section">

                <h3>Homepage</h3>

                <FormUtils.GroupSetFileUpload
                    fieldName="HomePageFileBinary"
                    title="Home Page Image"
                    controlSize="large"
                    names={{
                        fileName: 'homePageFileName',
                        fileBinary: 'homePageFileBinary',
                        fileSize: 'homePageFileSize',
                        fileType: 'homePageFileType'
                    }}
                />

                <ReactQuill
                    theme="snow"
                    onChange={handleChange}
                    value={props.values.homePageText}
                    modules={modules}
                    formats={formats}
                    bounds={'.app'}
                    placeholder="Edit Text"
                />
            </div>
        </Col>

        <h3 className="mt-4">Liability Message</h3>

        <Col sm="12" md="12">
            <div className="form-section">

                <ReactQuill
                    theme="snow"
                    onChange={handleLiabilityChange}
                    value={props.values.liabilityMessage}
                    modules={modules}
                    formats={formats}
                    bounds={'.app'}
                    placeholder="Edit Liability Message"
                />
            </div>
        </Col>

        <h3 className="mt-4">Resources</h3>

        <Col sm="12" md="6">
            <div className="form-section">
                <h4>Community</h4>

                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkText1"
                    title="Link Text 1"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceCommLinkMediaLibraryId1"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkUrl1"
                    title="Link URL 1"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceCommLinkDesc1"
                    title="Link Description 1"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkText2"
                    title="Link Text 2"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceCommLinkMediaLibraryId2"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkUrl2"
                    title="Link URL 2"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceCommLinkDesc2"
                    title="Link Description 2"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkText3"
                    title="Link Text 3"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceCommLinkMediaLibraryId3"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkUrl3"
                    title="Link URL 3"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceCommLinkDesc3"
                    title="Link Description 3"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkText4"
                    title="Link Text 4"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceCommLinkMediaLibraryId4"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkUrl4"
                    title="Link URL 4"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceCommLinkDesc4"
                    title="Link Description 4"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkText5"
                    title="Link Text 5"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceCommLinkMediaLibraryId5"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkUrl5"
                    title="Link URL 5"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceCommLinkDesc5"
                    title="Link Description 5"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkText6"
                    title="Link Text 6"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceCommLinkMediaLibraryId6"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceCommLinkUrl6"
                    title="Link URL 6"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceCommLinkDesc6"
                    title="Link Description 6"
                />
            </div>
        </Col>

        <Col sm="12" md="6">
            <div className="form-section">

                <h4>Facility</h4>

                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkText1"
                    title="Link Text 1"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceFacLinkMediaLibraryId1"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkUrl1"
                    title="Link URL 1"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceFacLinkDesc1"
                    title="Link Description 1"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkText2"
                    title="Link Text 2"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceFacLinkMediaLibraryId2"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkUrl2"
                    title="Link URL 2"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceFacLinkDesc2"
                    title="Link Description 2"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkText3"
                    title="Link Text 3"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceFacLinkMediaLibraryId3"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkUrl3"
                    title="Link URL 3"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceFacLinkDesc3"
                    title="Link Description 3"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkText4"
                    title="Link Text 4"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceFacLinkMediaLibraryId4"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkUrl4"
                    title="Link URL 4"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceFacLinkDesc4"
                    title="Link Description 4"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkText5"
                    title="Link Text 5"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceFacLinkMediaLibraryId5"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkUrl5"
                    title="Link URL 5"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceFacLinkDesc5"
                    title="Link Description 5"
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkText6"
                    title="Link Text 6"
                />
                <FormUtils.GroupSetDropdownAsync<MediaLibrary, number>
                    fieldName="resourceFacLinkMediaLibraryId6"
                    title="Media Library File"
                    dropdownProps={
                        {
                            getId: x => x.mediaLibraryId,
                            loadDataSource: () => Services.MediaLibraryService.list(),
                            renderSelectedItem: x => <>{x.mediaLibraryName} <div className="ms-2"></div></>,
                            renderItem: x => <div className="d-flex">{x.mediaLibraryName} <div className="ms-2"></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center',
                            allowNullSelection: true
                        }
                    }
                />
                <FormUtils.GroupSetText
                    fieldName="resourceFacLinkUrl6"
                    title="Link URL 6"
                />
                <FormUtils.GroupSetTextArea
                    fieldName="resourceFacLinkDesc6"
                    title="Link Description 6"
                />
            </div>

        </Col>
    </Row>);
}

