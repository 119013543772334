import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { GenericDropdown, GenericDropdownProps } from '../controls/GenericDropdown';
import { useContext, useReducer, useRef } from 'react';
import ModalComponent from '../ModalComponent';
import { Button } from 'reactstrap';
import { Result } from '../../services/ServiceBase';


type State<T> = {
    items: T[];
    isLoading: boolean;
    isNoResults: boolean;
    dataLoaded: boolean;
}

export function GroupSetModalMultiSelect<T, ID>(props: GroupSetProps & {
    selectedItemView: (id: ID) => JSX.Element,
    getId: (item: T) => ID,
    tableHead: JSX.Element,
    numberOfColumns: number,
    listAll: () => Promise<Result<T[]>>
    modalProps: {
        title: string;
    },
    rowView: (item: T, index: number) => JSX.Element,

}) {

    const [field, meta, helpers] = useField<ID[]>(props.fieldName);

    const [state, setState] = useReducer(
        (prevState: State<T>, newState: Partial<State<T>>) => ({ ...prevState, ...newState }),
        {
            items: [],
            isLoading: false,
            dataLoaded: false,
            isNoResults: false
        }
    );

    //const elModal = useRef<ModalComponent>();	
    let elModal: ModalComponent | null;

    const loadItems = async () => {

        setState({
            dataLoaded: true,
            isLoading: true
        })

        var result = await props.listAll();
        if (!result.hasErrors) {
            setState({
                items: result.value,
                isNoResults: result.value.length == 0,
                isLoading: false
            });
        }

    }

    return (<GroupSetCustom
        title={props.title}
        fieldName={props.fieldName}
        controlSize={props.controlSize}
        required={props.required}
    >
        <div>
            <ul>
                {(field.value || []).map(x => <li key={x + ''}>{props.selectedItemView(x)}</li>)}
            </ul>

            <Button color="primary" onClick={() => {
                if (!state.dataLoaded) {
                    loadItems();
                }
                elModal?.show();
            }}>Edit</Button>
            <ModalComponent modalTitle={props.modalProps.title} ref={x => elModal = x}>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            {props.tableHead}
                        </thead>
                        <tbody>
                            {state.items.map((x, index) => props.rowView(x, index))}
                        </tbody>
                    </table>
                </div>
            </ModalComponent>
        </div>
    </GroupSetCustom>)
}

