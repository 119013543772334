import * as React from 'react';
import { useState, useEffect, useId, useReducer } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { DrugFormulation } from '../../models/DrugFormulation';

type ItemID = any;

export type LabeledDropdownProps<T> = {
    dataSource: DrugFormulation[];
    renderItem: (item: DrugFormulation) => React.ReactNode;
    renderSelectedItem: (item: DrugFormulation) => React.ReactNode;
    onSelectedItemChanged?: (selectedItem: DrugFormulation | null) => void;
    getId: (item: DrugFormulation) => ItemID;
    selectedValue?: ItemID | undefined;
    allowNullSelection?: boolean;
    nullSelectionText?: string;
    className?: string;
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
    disabled?: boolean;
    renderAlways?: boolean;
}

export function LabeledDropdown<DrugFormulation>(props: LabeledDropdownProps<DrugFormulation>) {

    let itemByValue = (props.selectedValue != undefined)
        ? props.dataSource.find(x => props.selectedValue == props.getId(x))
        : undefined;

    const [selectedItem, setSelectedItem] = useState(itemByValue)

    useEffect(() => {

        if (props.selectedValue != undefined) {
            var itemByValue = props.dataSource.find(x => props.selectedValue == props.getId(x));
            setSelectedItem(itemByValue);

        } else {
            setSelectedItem(undefined);
        }

    }, [props.selectedValue, props.dataSource])

    const ddId = useId();

    return (<React.Fragment>
        <UncontrolledDropdown id={ddId} className={(props.className || '')} onBlur={props.onBlur} disabled={props.disabled}>
            <DropdownToggle caret className={'w-100 ' + (props.disabled ? ' disabled' : '')} >
                {
                    (typeof selectedItem !== 'undefined')
                        ? props.renderSelectedItem(selectedItem)
                        : (props.nullSelectionText || 'Select')
                }
            </DropdownToggle>
            {
                props.dataSource != null && props.dataSource.length > 0
                    ? <DropdownMenu>
                        <div className="dropdown-menu-inner">
                            {props.allowNullSelection === true &&
                                <DropdownItem onClick={x => {
                                    props.onSelectedItemChanged && props.onSelectedItemChanged(null);
                                    setSelectedItem(undefined);
                                }} className="clear-selection">
                                    {props.nullSelectionText || "Select"}
                                </DropdownItem>
                            }
                            {props.dataSource.map(
                                (item, index) => <>
                                    {(index == 0 || item.type != props.dataSource[index - 1].type) && <h5>{item.type}</h5>}
                                    <DropdownItem key={props.getId(item).toString()} onClick={x => {
                                        if (props.onSelectedItemChanged)
                                            props.onSelectedItemChanged(item);
                                        else
                                            setSelectedItem(item);
                                    }}>
                                        {props.renderItem(item)}
                                    </DropdownItem>
                                    
                                </>)}
                        </div>
                    </DropdownMenu>
                    : null
            }
        </UncontrolledDropdown>
    </React.Fragment>);
}

