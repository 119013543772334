import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { TaskType } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";


export function TaskTypeEditorFields(props: FormikProps<TaskType>) {

    //const values = props.values as TaskType;

    //const isReadOnly = false;

    return (<Row>
        <Col sm="6" md="4">
            <div className="form-section">
                <FormUtils.GroupSetText
                    fieldName="taskName"
                    title="Task Name"
                    required
                />

                <FormUtils.GroupSetCheckbox
                    fieldName="isActive"
                    title="Active"
                />
            </div>
        </Col>
    </Row>);
}

