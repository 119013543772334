import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { DrugFormulation } from '../models';


export class DrugFormulationService extends ServiceBase {

    public static async save(dto: DrugFormulation): Promise<Result<DrugFormulation>> {

        var result = await this.requestJson<DrugFormulation>({
            url: `api/DrugFormulation/save`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async delete(dto: DrugFormulation): Promise<Result<DrugFormulation>> {

        var result = await this.requestJson<DrugFormulation>({
            url: `api/DrugFormulation/delete`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async get(id: number): Promise<Result<DrugFormulation>> {

        var result = await this.requestJson<DrugFormulation>({
            url: `api/DrugFormulation/get/${id}`,
            method: "GET"   
        });

        return result;
    }

    public static async list(showAll: boolean): Promise<Result<DrugFormulation[]>> {

        var result = await this.requestJson<DrugFormulation[]>({
            url: `api/DrugFormulation/list?showAll=${showAll}`,
            method: "GET"            
        });

        return result;
    }

    public static async search(offset: number, pageSize: number): Promise<Result<PagedResult<DrugFormulation>>> {

        var result = await this.requestJson<PagedResult<DrugFormulation>>({
            url: `api/DrugFormulation/search`,
            method: "POST",
            data: { offset, pageSize }
        });

        return result;
    }

}
