import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import Dropzone from 'react-dropzone';


export const GroupSetMultiFileUpload = (props: GroupSetProps) => {

    const [field, meta, helpers] = useField<File[]>(props.fieldName);

    return (<GroupSetCustom {...props}>
        <Dropzone multiple onDrop={acceptedFiles => {
            helpers.setValue((field.value || []).concat(acceptedFiles));
        }}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()} className="dropzone-files">
                        <input {...getInputProps()} />
                        <div className="dropzone-message">
                            <i className="fas fa-cloud-upload-alt"></i>
                            <div>
                                Drag and drop files from an open Explorer window,<br />
                                or click within this grey box to browse for files.
                            </div>
                        </div>
                        <div>
                            {(field.value || []).map((x, index) => <div key={index}><b>{x.name}</b></div>)}
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    </GroupSetCustom>);
}