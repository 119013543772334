import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { Drug } from '../models';


export class DrugService extends ServiceBase {

    public static async save(dto: Drug): Promise<Result<Drug>> {

        var result = await this.requestJson<Drug>({
            url: `api/drug/save`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async delete(dto: Drug): Promise<Result<Drug>> {

        var result = await this.requestJson<Drug>({
            url: `api/drug/delete`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async get(id: number): Promise<Result<Drug>> {

        var result = await this.requestJson<Drug>({
            url: `api/drug/get/${id}`,
            method: "GET"            
        });

        return result;
    }

    public static async search(offset: number, pageSize: number): Promise<Result<PagedResult<Drug>>> {

        var result = await this.requestJson<PagedResult<Drug>>({
            url: `api/drug/search`,
            method: "POST",
            data: { offset, pageSize }
        });

        return result;
    }

    public static async list(showAll: boolean): Promise<Result<Drug[]>> {

        var result = await this.requestJson<Drug[]>({
            url: `api/drug/list?showAll=${showAll}`,
            method: "GET"
        });

        return result;
    }
}
