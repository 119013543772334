import * as yup from 'yup';

export class DrugFormulation {
    id: number;
    type: string;
    name: string;
    isActive: boolean;

    constructor() {
        this.id = 0;
        this.type = '';
        this.name = '';
        this.isActive = true
    }
}


export const ValidationSchema = yup.object().shape({
    name: yup.string().required('Required')
});

