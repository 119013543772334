import { useReducer, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
/*import * as FormUtils from './formUtils/index';*/

import * as Models from '../models';
import { HDAdminService } from '../services/HDAdminService';

type State = {
    isLoading: boolean;
    isAuthenticated: boolean;
}

export const Logout = () => {

    const mountRef = useRef(true);

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            isAuthenticated: false
        }
    );

    const logoutUser = async () => {
        const result = await HDAdminService.logout();

        if (!result.hasErrors && mountRef.current) {
            setState({ isAuthenticated: !result.value })
        }
    }

    // on component mount
    useEffect(() => {

        logoutUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>

        {!state.isAuthenticated && <>
            <Row>
                <Col sm="12" md="8">
                    You have been logged out.
                </Col>
            </Row>
        </>}
    </>
}
