import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Spinner } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ModalComponent from "../ModalComponent";
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import { useContext, useEffect, useReducer, useRef } from 'react';
import { PagedResult, Result } from '../../services/ServiceBase';
import { UIMessages } from '../UIUtils';
import InfiniteScroll from 'react-infinite-scroller';


type GenericEditorState<TModel, K> = {
    items: TModel[];
    totalItems: number;
    canLoadMore: boolean;
    isNoResults: boolean;
    isLoading: boolean;
}

function GenericListEditor<TModel, TFilter>(props: {
    pageSize?: number;
    search: (filter: TFilter, offset: number, pageSize: number) => Promise<Result<PagedResult<TModel>>>;
    getKey: (model: TModel) => string;
    colSpan: number;
    tableHead: React.ReactNode;
    tableBodyRow: (model: TModel, index: number) => React.ReactNode;
    filter: TFilter;
    addNewRoute: string;
}) {
    const mountRef = useRef(true);

    const navigate = useNavigate();

    let [state, setState] = useReducer(
        (prevState: GenericEditorState<TModel, TFilter>, newState: Partial<GenericEditorState<TModel, TFilter>>) => ({ ...prevState, ...newState }),
        {
            items: [],
            canLoadMore: false,
            totalItems: 0,
            isNoResults: false,
            isLoading: true
        }
    );

    const search = async () => {

        setState({
            isLoading: true
        });

        var result = await props.search(props.filter, 0, props.pageSize ?? 10);

        if (!mountRef.current)
            return;

        if (!result.hasErrors) {
            setState({
                isLoading: false,
                items: result.value.items,
                totalItems: result.value.totalItems,
                canLoadMore: result.value.items.length < result.value.totalItems,
                isNoResults: result.value.totalItems == 0
            });
        } else {
            setState({
                isLoading: false,
                items: [],
                totalItems: 0,
                canLoadMore: false,
                isNoResults: false
            });
        }
    }

    const loadMore = async () => {

        setState({
            isLoading: true
        });

        var result = await props.search(props.filter, state.items.length, props.pageSize ?? 10);

        if (!mountRef.current)
            return;

        if (!result.hasErrors) {

            var newItemsSet = state.items.concat(result.value.items);

            setState({
                isLoading: false,
                items: newItemsSet,
                totalItems: result.value.totalItems,
                canLoadMore: newItemsSet.length < result.value.totalItems,
                //isNoResults: result.value.totalItems == 0
            });
        } else {
            setState({
                isLoading: false,
                items: [],
                totalItems: 0,
                canLoadMore: false,
                //isNoResults: false
            });
        }
    };

    // on component did mount
    useEffect(() => {

        search();

        return () => {
            mountRef.current = false;
        }

    }, []);


    return (
        <>
            <div className="d-flex justify-content-between">
                <div>
                    {state.isNoResults && <h5>No Results</h5>}
                    {!state.isNoResults && <h5>total {state.totalItems} items</h5>}
                </div>
                <Button className="btn btn-primary" onClick={() => navigate(props.addNewRoute)}>Add New</Button>
            </div>
            <div className="scrollable-listing">
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={state.canLoadMore && !state.isLoading}
                >
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                {props.tableHead}
                            </thead>
                            <tbody>
                                {state.items.map((item, index) => {
                                    return props.tableBodyRow(item, index);
                                })
                                }
                                {state.isNoResults &&
                                    <tr>
                                        <td colSpan={props.colSpan}>No Results</td>
                                    </tr>
                                }
                                {state.isLoading &&
                                    <tr>
                                        <td colSpan={props.colSpan}>
                                            <Spinner color="primary" size="sm" />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </InfiniteScroll>
            </div>
        </>
    );
}

export default GenericListEditor;