import { useReducer, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
/*import * as FormUtils from './formUtils/index';*/
import { Login } from "../models/Login";
import Cookies from 'js-cookie';

import * as Models from '../models';
import { HDAdminService } from '../services/HDAdminService';

type State = {
    isLoading: boolean;
    isAuthenticated: boolean;
    isGlobalAdmin: boolean;
    loginError: string;
    username: string;
    password: string;
}

export const Admin = () => {

    const mountRef = useRef(true);

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            isAuthenticated: false,
            isGlobalAdmin: false,
            loginError: '',
            username: '',
            password: ''
        }
    );

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        console.log(result);

        if (!result.hasErrors && mountRef.current) {
            setState({
                isAuthenticated: result.value.adminUserId != null,
                isGlobalAdmin: result.value.adminIsMaster
            })
        }
    }

    const loginUser = async () => {

        var loginUser: Login = { UserName: state.username, Password: state.password };
        const result = await HDAdminService.login(loginUser);

        console.log(result);

        if (!result.hasErrors && mountRef.current && result.value) {
            setState({ isAuthenticated: true })
        } else {
            setState({ loginError: "Sorry, username or password is incorrect." })
        }
    }

    const handleUsernameChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({ username: value });
    }

    const handlePasswordChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({ password: value });
    }

    const logOut = () => {
        window.location.href = '/logout';
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>

        {state.isAuthenticated == true && <>
            <Row>
                <Col sm="6">
                    <h2>ShielD Admin</h2>
                </Col>
                <Col sm="6" className="text-end">
                    <Button className="btn" onClick={ logOut }>Logout</Button>
                </Col>
            </Row>
            <Row>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Site Settings</h5>
                            <p className="card-text">Edit the photo on the homepage, or the Resources page content.</p>
                            <a href="/sitesettings/1" className="btn btn-primary">Edit Site Settings</a>
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Help Page</h5>
                            <p className="card-text">Edit the help page.</p>
                            <a href="/helpinfo/1" className="btn btn-primary">Edit Help Page</a>
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Media Library</h5>
                            <p className="card-text">Edit or add PDFs and other files that are used on the Resources page</p>
                            <a href="/medialibrary-list" className="btn btn-primary">Edit Media Library</a>
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Drugs</h5>
                            <p className="card-text">Edit the Drugs listed in ShielD.</p>
                            <a href="/drugs-list" className="btn btn-primary">Edit Drugs</a>
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Task Types</h5>
                            <p className="card-text">Edit the Task Types listed in ShielD.</p>
                            <a href="/task-type-list" className="btn btn-primary">Edit Task Types</a>
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Formulation/Hazards</h5>
                            <p className="card-text">Edit the  listed in ShielD.</p>
                            <a href="/drug-formulations-list" className="btn btn-primary">Edit Formulation/Hazards</a>
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">PPEs</h5>
                            <p className="card-text">Edit the Personal Protective Equipment listed in ShielD.</p>
                            <a href="/ppe-list" className="btn btn-primary">Edit PPEs</a>
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">HD Mapping</h5>
                            <p className="card-text">Edit the HD Mapping listed in ShielD.</p>
                            <a href="/hd-maps-list" className="btn btn-primary">Edit HD Mapping</a>
                        </div>
                    </div>
                </Col>
                {state.isGlobalAdmin && <Col lg="3" className="align-items-stretch mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Admins</h5>
                            <p className="card-text">Manage the users in ShielD</p>
                            <a href="/hdadmin-list" className="btn btn-primary">Edit Admins</a>
                        </div>
                    </div>
                </Col>}
            </Row>
        </>}

        {!state.isAuthenticated &&
            <Row>
                <Col sm="12" md="8">
                    <div className="form-section">
                        <p>
                            <label>Username</label><br />
                            <input type="text" onChange={handleUsernameChange} />
                        </p>

                        <p>
                            <label>Password</label><br />
                            <input type="password" onChange={handlePasswordChange} />
                        </p>

                        <Button onClick={loginUser}>Login</Button>

                        {state.loginError && <div className="mt-4 alert alert-warning">{state.loginError}</div>}

                    </div>

                </Col>
            </Row>
        }
    </>
}
