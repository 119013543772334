import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { SiteSettings } from '../models';


export class SiteSettingsService extends ServiceBase {

    public static async save(dto: SiteSettings): Promise<Result<SiteSettings>> {

        var result = await this.requestJson<SiteSettings>({
            url: `api/SiteSettings/save`,
            method: "POST",
            data: dto            
        }, undefined, true);

        return result;
    }

    public static async get(id): Promise<Result<SiteSettings>> {

        var result = await this.requestJson<SiteSettings>({
            url: `api/SiteSettings/get/`,
            method: "GET"            
        });

        return result;
    }
}
