import { useReducer, useEffect, useRef } from 'react';
import GenericListEditor from './GenericListEditor';
import { DrugFormulation, ValidationSchema } from '../../models/DrugFormulation';
import { DrugFormulationService } from '../../services/DrugFormulationService';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';

export const DrugFormulationListEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <div className="mb-2 text-end"><a href="/admin">Return to Admin</a></div>
        <GenericListEditor<DrugFormulation, {}>
            colSpan={4}
            filter={{}}
            getKey={x => x.id.toString()}
            search={(filter, offset, pageSize) => DrugFormulationService.search(offset, pageSize)}
            tableHead={<tr>
                <th>#</th>
                <th>Drug Formulation Name</th>
                <th>Type</th>
                <th>Active</th>
                <th>Actions</th>
            </tr>}
            tableBodyRow={(item, index) => <tr key={item.id.toString()}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td>{item.isActive ? "Yes" : "No"}</td>
                <th> <NavLink tag={Link} to={`/drug-formulations-edit/${item.id}`} >edit</NavLink></th>
            </tr>}
            addNewRoute={"/drug-formulations-add"}
        />
    </>;
}