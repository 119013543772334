import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { GenericDropdown, GenericDropdownProps } from '../controls/GenericDropdown';
import { useContext } from 'react';


export function GroupSetDropdown<T>(props: GroupSetProps & { dropdownProps: GenericDropdownProps<T> }) {

    const [field, meta, helpers] = useField(props.fieldName);

    const originalOnChange = props.dropdownProps.onSelectedItemChanged;

    return (<GroupSetCustom
        title={props.title}
        fieldName={props.fieldName}
        controlSize={props.controlSize}
        required={props.required}
    >
        <GenericDropdown
            {...props.dropdownProps}
            selectedValue={field.value}
            onSelectedItemChanged={(item) => {

                helpers.setValue(typeof item !== 'undefined' ? props.dropdownProps.getId(item as T) : undefined)
                if (originalOnChange)
                    originalOnChange(item);
            }}
            disabled={props.disabled}
        />

    </GroupSetCustom>)
}

