import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { DrugFormulation } from "../../models/index";
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";


export function DrugFormulationEditorFields(props: FormikProps<DrugFormulation>) {

    let types = ["Drug Formulation", "Hazard Type"];

    let getType = (type) => {
        let selectedType = types.find((t) => t == type);

        console.log('selectedType', selectedType);

        return selectedType;
    }

    return (<Row>
        <Col sm="6" md="4">
            <div className="form-section">
                <FormUtils.GroupSetText
                    fieldName="name"
                    title="Name"
                    required
                />

                <FormUtils.GroupSetText
                    fieldName="type"
                    title="Type"
                    required
                />

                <FormUtils.GroupSetCheckbox
                    fieldName="isActive"
                    title="Active"
                />
            </div>
        </Col>
    </Row>);
}

