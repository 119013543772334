import * as React from 'react';
import { useState, useEffect, useId } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

type ItemID = any;

export type GenericDropdownProps<T> = {
    dataSource: T[];
    renderItem: (item: T) => React.ReactNode;
    renderSelectedItem: (item: T) => React.ReactNode;
    onSelectedItemChanged?: (selectedItem: T | null) => void;
    getId: (item: T) => ItemID;
    selectedValue?: ItemID | undefined;
    allowNullSelection?: boolean;
    nullSelectionText?: string;
    className?: string;
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
    disabled?: boolean;
    renderAlways?: boolean;
}

export function GenericDropdown<T>(props: GenericDropdownProps<T>) {

    let itemByValue = (props.selectedValue != undefined)
        ? props.dataSource.find(x => props.selectedValue == props.getId(x))
        : undefined;

    const [selectedItem, setSelectedItem] = useState(itemByValue)

    useEffect(() => {

        if (props.selectedValue != undefined) {
            var itemByValue = props.dataSource.find(x => props.selectedValue == props.getId(x));
            setSelectedItem(itemByValue);

        } else {
            setSelectedItem(undefined);
        }

    }, [props.selectedValue, props.dataSource])

    const ddId = useId();

    return (<React.Fragment>
        <UncontrolledDropdown id={ddId} className={(props.className || '')} onBlur={props.onBlur} disabled={props.disabled}>
            <DropdownToggle caret className={'w-100 ' + (props.disabled ? ' disabled' : '')} >
                {
                    (typeof selectedItem !== 'undefined')
                        ? props.renderSelectedItem(selectedItem)
                        : (props.nullSelectionText || 'Select')
                }
            </DropdownToggle>
            {
                props.dataSource != null && props.dataSource.length > 0
                    ? <DropdownMenu>
                        <div className="dropdown-menu-inner">
                            {props.allowNullSelection === true &&
                                <DropdownItem onClick={x => {
                                    props.onSelectedItemChanged && props.onSelectedItemChanged(null);
                                    setSelectedItem(undefined);
                                }} className="clear-selection">
                                    {props.nullSelectionText || "Select"}
                                </DropdownItem>
                            }
                            {props.dataSource.map(
                                (item: T) => <DropdownItem key={props.getId(item).toString()} onClick={x => {
                                    if (props.onSelectedItemChanged)
                                        props.onSelectedItemChanged(item);
                                    else
                                        setSelectedItem(item);
                                }}>
                                    {props.renderItem(item)}
                                </DropdownItem>)}
                        </div>
                    </DropdownMenu>
                    : null
            }
        </UncontrolledDropdown>
    </React.Fragment>);
}

