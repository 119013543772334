import * as yup from 'yup';
import { CommunityFacility } from ".";

export class HDMap {
    id: number;
    communityFacility: CommunityFacility;
    taskTypeId: number;
    drugGroupId: number;
    drugFormulationId: number;
    personalProtectiveEquipmentIds: number[];
    primaryMessage: string;
    secondaryMessage: string;
    isActive: boolean;
}

export const ValidationSchema = yup.object().shape({
    //name: yup.string().required('Required')
});