import * as React from 'react';
import { useContext, useEffect, useId, useRef, useState } from 'react';
import { ErrorDisplay, GroupSetCustom, GroupSetProps, Label } from './index';
import { useField, Field } from "formik";
import { Label as RLabel } from 'reactstrap';
import cn from 'classnames';
import { Result } from '../../services/ServiceBase';


export function GroupSetRadioAsync<T>(props: GroupSetProps & {
    //value: string, //options: string[],
    vertical?: boolean,
    preprocessDataSource?: (items: T[]) => T[],
    dataSourceDependentParams?: any[],
    loadDataSource: () => Promise<Result<T[]>>;
    getValue: (item: T) => string;
    getDisplayText: (item: T) => string;
    //getDisplayValuePair: (item: T) => string;
    valueAsBoolean?: boolean;
    bottomContent?: React.ReactNode;
}) {

    const mountRed = useRef(true);
    const [ready, setReady] = useState(false);
    const [dataSource, setDataSource] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [field, meta, helpers] = useField(props.fieldName);

    const loadDataSource = () => {
        setIsLoading(true);

        props.loadDataSource().then(x => {

            if (!mountRed.current)
                return;

            if (!x.hasErrors) {

                let ds = (props.preprocessDataSource)
                    ? props.preprocessDataSource(x.value)
                    : x.value;

                setDataSource(ds);
            }

            setReady(true);
            setIsLoading(false);
        });
    }

    useEffect(() => {

        loadDataSource();

    }, props.dataSourceDependentParams || []);

    return (
        <div className={cn("form-group mb-4", {
            "form-check-inline": !props.vertical,
            "form-check-vertical": props.vertical
        })}>
            <Label title={props.title} fieldName={props.fieldName} required={props.required} />
            <div>
                {dataSource.map(x => <Radiobox key={props.getValue(x)} name={props.fieldName} disabled={props.disabled} value={`${props.getValue(x)};${props.getDisplayText(x)}`} />)}
                {meta.error && meta.touched && <ErrorDisplay fieldName={props.fieldName} />}
                {props.explanationText && <div className="explanation">{props.explanationText}</div>}
            </div>
            
        </div>);
}

export const GroupSetRadio = (props: GroupSetProps & { valueAsBoolean?: boolean, options: string[], vertical?: boolean }) => {

    const [field, meta, helpers] = useField(props.fieldName);

    return (
        <div className={cn("form-group mb-4", {
            "form-check-inline": !props.vertical,
            "form-check-vertical": props.vertical
        })}>
            <Label title={props.title} fieldName={props.fieldName} className="form-label lead" required={props.required}></Label>
            <div>
                {props.options.map(x => <Radiobox key={x} name={props.fieldName} value={x} valueAsBoolean={props.valueAsBoolean} disabled={props.disabled} />)}
                {meta.error && meta.touched && <ErrorDisplay fieldName={props.fieldName} />}
                {props.explanationText && <div className="explanation">{props.explanationText}</div>}
            </div>            
        </div>);
}

function Radiobox(props: {
    value: string,
    name: string,
    valueAsBoolean?: boolean,
    disabled?: boolean
}) {
    const checkBoxId = useId();

    const optionValue = props.value.indexOf(';') > -1 ? props.value.substr(0, props.value.indexOf(';')) : props.value;
    const optionText = props.value.indexOf(';') > -1 ? props.value.substr(props.value.indexOf(';') + 1) : props.value;

    const [field, meta, helper] = useField(props.name);

    let defaultChecked = field.value == optionValue;
    if (props.valueAsBoolean && field.value != undefined) {
        defaultChecked = field.value.toString() == optionValue;
    }

    return (<div className="radio-option">
        <input
            id={checkBoxId}
            //{...field}
            name={field.name}
            type="radio"
            value={optionValue}
            //defaultChecked={field.value as string == optionValue}
            disabled={props.disabled}
            defaultChecked={defaultChecked}
            onChange={(e) => {

                if (props.valueAsBoolean)
                    helper.setValue(optionValue == 'true', true);
                else
                    helper.setValue(optionValue, true);                
            }}
            className="form-check-input"
        />
        <RLabel check for={checkBoxId} className="ms-1 me-4" dangerouslySetInnerHTML={{ __html: optionText }}>
            
        </RLabel>
    </div>)    
}