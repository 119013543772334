import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { useContext } from 'react';
import PhoneInput from 'react-phone-input-2';
//import 'react-phone-input-2/lib/style.css';


export const GroupSetPhoneInput = (props: GroupSetProps & { maxLength?: number } & {inputProps?: React.InputHTMLAttributes<HTMLInputElement>}) => {
    const [field, meta, help] = useField(props.fieldName);

    const originalOnChange = field.onChange;

        
    if (props.onValueChanged) {

        field.onChange = (e) => {
            originalOnChange(e);
            props.onValueChanged?.();
        };
    }

    return (<GroupSetCustom {...props}>

        <PhoneInput
            country={'us'}
            value={field.value}
            onChange={phone => help.setValue(phone, true)}
            disabled={props.disabled}
        />
        
    </GroupSetCustom>);
}