import { useReducer, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';

import * as Models from '../models';
import { SiteSettingsService } from '../services/SiteSettingsService';

type State = {
    siteSettings?: Models.SiteSettings;
    isLoading: boolean;
}

export const Home = () => {

    const mountRef = useRef(true);

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            siteSettings: undefined,
            isLoading: false
        }
    );

    const loadSiteSettings = async () => {

        const result = await SiteSettingsService.get(1);

        if (!result.hasErrors && mountRef.current) {
            setState({ siteSettings: result.value })
        }
    }

    // on component mount
    useEffect(() => {

        loadSiteSettings();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <p><img src="/api/sitesettings/gethomepageimage/" alt="" className="img-responsive" /></p>
        <div className="quill-text" dangerouslySetInnerHTML={{ __html: state.siteSettings?.homePageText || '' }}></div>
    </>
}
