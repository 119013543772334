import classnames from 'classnames';

import { useReducer, useEffect, useRef } from 'react';
import { TabContent, TabPane, Button, Col, Row, Nav, NavItem, NavLink } from 'reactstrap';

import * as Models from '../models';
import { SiteSettingsService } from '../services/SiteSettingsService';

type State = {
    activeTab: string;
    siteSettings?: Models.SiteSettings;
    isLoading: boolean;
}

export const Resources = () => {

    const mountRef = useRef(true);

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            activeTab: '1',
            siteSettings: undefined,
            isLoading: false
        }
    );

    const loadSiteSettings = async () => {

        const result = await SiteSettingsService.get(1);

        if (!result.hasErrors && mountRef.current) {
            setState({ siteSettings: result.value })
        }
    }

    const toggle = (tab: string) => {
        setState({ activeTab: tab })
    }

    // on component mount
    useEffect(() => {

        loadSiteSettings();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return (
        <>
            <div className="resource-tabs">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: state.activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            <img src={require('../images/community.png')} alt="Community" className="img-responsive" />
                            <div className="text-center">Community</div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: state.activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            <img src={require('../images/facility.png')} alt="Facility" className="img-responsive" />
                            <div className="text-center">Facility</div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: state.activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                            <img src={require('../images/hazard-groups.png')} alt="Hazardous Groups" className="img-responsive" />
                            <div className="text-center">Hazardous Drug Groups</div>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">

                                {state.siteSettings?.resourceCommLinkMediaLibraryId1 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceCommLinkMediaLibraryId1} target="_blank">{state.siteSettings?.resourceCommLinkText1}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc1 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceCommLinkMediaLibraryId1 == 0 || state.siteSettings?.resourceCommLinkMediaLibraryId1 == null) && state.siteSettings?.resourceCommLinkUrl1 && <p><a href={state.siteSettings?.resourceCommLinkUrl1} target="_blank">{state.siteSettings?.resourceCommLinkText1}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc1 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceCommLinkMediaLibraryId2 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceCommLinkMediaLibraryId2} target="_blank">{state.siteSettings?.resourceCommLinkText2}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc2 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceCommLinkMediaLibraryId2 == 0 || state.siteSettings?.resourceCommLinkMediaLibraryId2 == null) && state.siteSettings?.resourceCommLinkUrl2 && <p><a href={state.siteSettings?.resourceCommLinkUrl2} target="_blank">{state.siteSettings?.resourceCommLinkText2}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc2 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceCommLinkMediaLibraryId3 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceCommLinkMediaLibraryId3} target="_blank">{state.siteSettings?.resourceCommLinkText3}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc3 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceCommLinkMediaLibraryId3 == 0 || state.siteSettings?.resourceCommLinkMediaLibraryId3 == null) && state.siteSettings?.resourceCommLinkUrl3 && <p><a href={state.siteSettings?.resourceCommLinkUrl3} target="_blank">{state.siteSettings?.resourceCommLinkText3}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc3 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceCommLinkMediaLibraryId4 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceCommLinkMediaLibraryId4} target="_blank">{state.siteSettings?.resourceCommLinkText4}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc4 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceCommLinkMediaLibraryId4 == 0 || state.siteSettings?.resourceCommLinkMediaLibraryId4 == null) && state.siteSettings?.resourceCommLinkUrl4 && <p><a href={state.siteSettings?.resourceCommLinkUrl4} target="_blank">{state.siteSettings?.resourceCommLinkText4}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc4 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceCommLinkMediaLibraryId5 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceCommLinkMediaLibraryId5} target="_blank">{state.siteSettings?.resourceCommLinkText5}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc5 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceCommLinkMediaLibraryId5 == 0 || state.siteSettings?.resourceCommLinkMediaLibraryId5 == null) && state.siteSettings?.resourceCommLinkUrl5 && <p><a href={state.siteSettings?.resourceCommLinkUrl5} target="_blank">{state.siteSettings?.resourceCommLinkText5}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc5 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceCommLinkMediaLibraryId6 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceCommLinkMediaLibraryId6} target="_blank">{state.siteSettings?.resourceCommLinkText6}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc6 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceCommLinkMediaLibraryId6 == 0 || state.siteSettings?.resourceCommLinkMediaLibraryId6 == null) && state.siteSettings?.resourceCommLinkUrl6 && <p><a href={state.siteSettings?.resourceCommLinkUrl6} target="_blank">{state.siteSettings?.resourceCommLinkText6}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceCommLinkDesc6 || '' }}></div>
                                </p>}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                {state.siteSettings?.resourceFacLinkMediaLibraryId1 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceFacLinkMediaLibraryId1} target="_blank">{state.siteSettings?.resourceFacLinkText1}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc1 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceFacLinkMediaLibraryId1 == 0 || state.siteSettings?.resourceFacLinkMediaLibraryId1 == null) && state.siteSettings?.resourceFacLinkUrl1 && <p><a href={state.siteSettings?.resourceFacLinkUrl1} target="_blank">{state.siteSettings?.resourceFacLinkText1}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc1 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceFacLinkMediaLibraryId2 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceFacLinkMediaLibraryId2} target="_blank">{state.siteSettings?.resourceFacLinkText2}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc2 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceFacLinkMediaLibraryId2 == 0 || state.siteSettings?.resourceFacLinkMediaLibraryId2 == null) && state.siteSettings?.resourceFacLinkUrl2 && <p><a href={state.siteSettings?.resourceFacLinkUrl2} target="_blank">{state.siteSettings?.resourceFacLinkText2}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc2 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceFacLinkMediaLibraryId3 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceFacLinkMediaLibraryId3} target="_blank">{state.siteSettings?.resourceFacLinkText3}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc3 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceFacLinkMediaLibraryId3 == 0 || state.siteSettings?.resourceFacLinkMediaLibraryId3 == null) && state.siteSettings?.resourceFacLinkUrl3 && <p><a href={state.siteSettings?.resourceFacLinkUrl3} target="_blank">{state.siteSettings?.resourceFacLinkText3}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc3 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceFacLinkMediaLibraryId4 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceFacLinkMediaLibraryId4} target="_blank">{state.siteSettings?.resourceFacLinkText4}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc4 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceFacLinkMediaLibraryId4 == 0 || state.siteSettings?.resourceFacLinkMediaLibraryId4 == null) && state.siteSettings?.resourceFacLinkUrl4 && <p><a href={state.siteSettings?.resourceFacLinkUrl4} target="_blank">{state.siteSettings?.resourceFacLinkText4}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc4 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceFacLinkMediaLibraryId5 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceFacLinkMediaLibraryId5} target="_blank">{state.siteSettings?.resourceFacLinkText5}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc5 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceFacLinkMediaLibraryId5 == 0 || state.siteSettings?.resourceFacLinkMediaLibraryId5 == null) && state.siteSettings?.resourceFacLinkUrl5 && <p><a href={state.siteSettings?.resourceFacLinkUrl5} target="_blank">{state.siteSettings?.resourceFacLinkText5}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc5 || '' }}></div>
                                </p>}

                                {state.siteSettings?.resourceFacLinkMediaLibraryId6 && <p><a href={'/api/medialibrary/getfile/' + state.siteSettings?.resourceFacLinkMediaLibraryId6} target="_blank">{state.siteSettings?.resourceFacLinkText6}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc6 || '' }}></div>
                                </p>}
                                {(state.siteSettings?.resourceFacLinkMediaLibraryId6 == 0 || state.siteSettings?.resourceFacLinkMediaLibraryId6 == null) && state.siteSettings?.resourceFacLinkUrl6 && <p><a href={state.siteSettings?.resourceFacLinkUrl6} target="_blank">{state.siteSettings?.resourceFacLinkText6}</a><br />
                                    <div dangerouslySetInnerHTML={{ __html: state.siteSettings?.resourceFacLinkDesc6 || '' }}></div>
                                </p>}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <h3>Hazardous Drug Groups</h3>
                                <p>Hazardous group 1 and 2 apply to both facility and community.</p>
                                <strong>Group 1</strong>
                                <ul>
                                    <li>Drugs which contain manufacturers' special handing information (MSHI)</li>
                                    <li>Drugs which meet the NIOSH definition of a hazardous drug and are <strong>classified</strong> by the national Toxicology Program (NTP) as "known to be a human carcinogen," and/or classified by the international Agency for Research on Cancer (IARC) as "carcinogenic" or "probably carcinogenic"</li>
                                    <li>Many of these drugs are cytotoxic and the majority are hazardous to males or females who are actively trying to conceive, women who are pregnant or may become pregnant, and women who are breast feeding, because the drugs may be excrete in breast milk</li>
                                    <li>Not all drugs in Level 1 are antineoplastic drugs</li>
                                </ul>
                                <div className="text-center"><img src={require('../images/hd-group1.png')} alt="HD Group 1" className="img-responsive" /></div>

                                <strong>Group 2</strong>
                                <ul>
                                    <li>Drug that meet the NIOSH definition of a hazardous drug but are not drugs which have MSHI and are <strong>not classified</strong> by the NTP as "known to be a human carcinogen," and/or classified by the IARC as "carcinogenic" or "probably carcinogenic"</li>
                                    <li>These drugs exhibit one or more of the types of toxicity described in the NIOSH definition of hazardous drug</li>
                                    <li>Some of these drugs may present an occupational hazard to males or females who are actively trying to conceive, women who are pregnant or may become pregnant, and women who are breast feeding, because they may be present in breast milk</li>
                                </ul>
                                <div className="text-center"><img src={require('../images/hd-group2.png')} alt="HD Group 2" className="img-responsive" /></div>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>

            <div className="alert alert-primary mt-4" dangerouslySetInnerHTML={{ __html: state.siteSettings?.liabilityMessage || '' }}></div>

        </>
    );
}