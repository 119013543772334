import React, { Component } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { useReducer, useEffect, useRef, useContext } from 'react';
import { GenericDropdown } from '../controls/GenericDropdown';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../formUtils/index';
import * as Models from '../../models';
import { SearchIn } from '../../services/HDMapService';


type State = {
    isOpened: boolean;
    model: SearchIn;
    searchWarning: boolean;
}

export const DrugMapSearchFilters = (props: {
    onFiltersChange: (filter: SearchIn) => void;
    dsCommunityFacility: Models.CommunityFacility[];
    dsDrugs: Models.Drug[];
    dsDrugFormulations: Models.DrugFormulation[];
    dsTaskType: Models.TaskType[];
}) => {

    let [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            isOpened: true,
            model: {
                communityFacility: Models.CommunityFacility.Both,
                drugId: 0,
                drugGroupId: 0,
                drugFormulationId: 0,
                taskTypeId: 0
            },
            searchWarning: false
        }
    );

    return <Formik enableReinitialize={true}
        initialValues={state.model}
        onSubmit={(values, actions) => {

            if (values.communityFacility == 2 || !values.drugId || !values.drugFormulationId || !values.taskTypeId) {
                setState({
                    searchWarning: true
                });
                return;
            }

            props.onFiltersChange(values);

            setState({ isOpened: false, searchWarning: false })
        }}
    >
        {(formikState) =>
            <>
                <div className="card mb-3 bg-light-blue border-blue">
                    <div className="card-body">
                        <Collapse isOpen={state.isOpened}>
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-5">
                                <Col>
                                    <FormUtils.GroupSetDropdown<Models.CommunityFacility>
                                        title="Work Location"
                                        fieldName="communityFacility"
                                        dropdownProps={{
                                            dataSource: props.dsCommunityFacility,
                                            getId: x => x,
                                            renderItem: x => Models.CommunityFacility[x],
                                            renderSelectedItem: x => Models.CommunityFacility[x],
                                            selectedValue: formikState.values.communityFacility
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <FormUtils.GroupSetDropdown<Models.Drug>
                                        title="Drug Name"
                                        fieldName="drugId"
                                        dropdownProps={{
                                            dataSource: props.dsDrugs,
                                            getId: x => x.id,
                                            renderItem: x => x?.drugName,
                                            renderSelectedItem: x => x?.drugName,
                                            selectedValue: formikState.values.drugId
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <FormUtils.GroupSetDropdown<Models.TaskType>
                                        title="Task Type"
                                        fieldName="taskTypeId"
                                        dropdownProps={{
                                            dataSource: props.dsTaskType,
                                            getId: x => x.id,
                                            renderItem: x => x?.taskName,
                                            renderSelectedItem: x => x?.taskName,
                                            selectedValue: formikState.values.taskTypeId
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <FormUtils.GroupSetLabeledDropdown<Models.DrugFormulation>
                                        title="Formulation/Hazard"
                                        fieldName="drugFormulationId"
                                        dropdownProps={{
                                            dataSource: props.dsDrugFormulations,
                                            getId: x => x.id,
                                            renderItem: x => x?.name,
                                            renderSelectedItem: x => x?.name,
                                            selectedValue: formikState.values.drugFormulationId
                                        }}
                                    />
                                </Col>
                                <Col className="d-flex align-items-end">
                                    <Button className="mb-4" color="primary" onClick={() => { formikState.submitForm() }}>Drug Look Up</Button>
                                </Col>
                            </Row>
                        </Collapse>
                        <Collapse isOpen={!state.isOpened}>
                            <Button color="primary" onClick={() => { setState({ isOpened: !state.isOpened }) }}>Make a new Search</Button>
                        </Collapse>
                    </div>
                </div>

                {state.searchWarning && <div className="alert alert-warning mt-4">All fields are required</div>}
            </>
        }
    </Formik>
}
