import * as yup from 'yup';


export class PersonalProtectiveEquipment {
    id: number;
    title: string;
    iconBinary: File;
    fileName: string;
    fileSize: number;
    fileType: string;
    isActive: boolean;
    

    constructor() {
        this.id = 0;
        this.title = '';        
        this.fileName = '';     
        this.fileSize = 0;
        this.fileType = '';
        this.isActive = true
    }
}

export const ValidationSchema = yup.object().shape({
    title: yup.string().required('Required')    
});

