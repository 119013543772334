import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { Drug, DrugGroup } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";


export function DrugEditorFields(props: FormikProps<Drug>) {

    //const values = props.values as Drug;

    //const isReadOnly = false;

    return (<Row>
        <Col sm="6" md="4">
            <div className="form-section">

                <FormUtils.GroupSetText
                    fieldName="drugName"
                    title="Drug Name"
                    required
                />

                <FormUtils.GroupSetDropdownAsync<DrugGroup, number>
                    fieldName="drugGroupId"
                    title="Group"
                    dropdownProps={
                        {
                            getId: x => x.id,
                            loadDataSource: () => Services.DrugGroupService.list(),
                            renderSelectedItem: x => <>{x.groupName} <div className={"ms-2 group-theme-bg-" + x.groupColor.toLowerCase()} style={{ height: '22px', width: '22px' }}></div></>,
                            renderItem: x => <div className="d-flex">{x.groupName} <div className={"ms-2 group-theme-bg-" + x.groupColor.toLowerCase()} style={{ height: '22px', width: '22px' }}></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center'
                        }
                    }
                />

                <FormUtils.GroupSetText
                    fieldName="drugOrder"
                    title="Order"
                    disabled
                    required
                />

                <FormUtils.GroupSetCheckbox
                    fieldName="isActive"
                    title="Active"
                />

            </div>

        </Col>
    </Row>);
}

