import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { useContext } from 'react';


export const GroupSetText = (props: GroupSetProps & { maxLength?: number } & {inputProps?: React.InputHTMLAttributes<HTMLInputElement>}) => {
    const [field, meta, help] = useField(props.fieldName);

    const originalOnChange = field.onChange;

        
    if (props.onValueChanged) {

        field.onChange = (e) => {
            originalOnChange(e);
            props.onValueChanged?.();
        };
    }


    return (<GroupSetCustom {...props}>
        <input {...field} {...props.inputProps} className={"form-control " + ((meta.error && meta.touched && "is-invalid") || '')} disabled={props.disabled} maxLength={props.maxLength ? props.maxLength : 200} />
    </GroupSetCustom>);
}