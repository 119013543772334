import * as yup from 'yup';

export class TaskType {
    id: number;
    taskName: number;
    isActive: boolean;
}


export const ValidationSchema = yup.object().shape({
    taskName: yup.string().required('Required')
});