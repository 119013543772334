import * as yup from 'yup';

export class HDAdmin {
    id: number;
    adminUserId: number;
    adminUserName: string;
    adminIsMaster: boolean;
    adminUserPassword: string;
    newAdminUserPassword: string;
    adminLoginDate?: Date;
    adminSessionGuid: string;
    isInactive: boolean;
}

export const ValidationSchema = yup.object().shape({
    adminUserName: yup.string().required('Required'),
    newAdminUserPassword: yup.string().matches(/^.*(?=.{12,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 12 characters, one uppercase, one number and one special case character")
});