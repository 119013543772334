import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import Dropzone from 'react-dropzone';
import { bytesToFriendly } from '../../utils';


export const GroupSetFileUpload = (props: GroupSetProps & {
    names?: {
        fileBinary?: string;
        fileName?: string;
        fileSize?: string;
        fileType?: string;
    }
}) => {

    let names = {
        ...{
            fileBinary: 'fileBinary',
            fileName: 'fileName',
            fileSize: 'fileSize',
            fileType: 'fileMimeType'
        }, ...props.names || {}
    };

    const [fieldBinary, metaBinary, helpersBinary] = useField(names.fileBinary);
    const [fieldName, metaName, helpersName] = useField(names.fileName);
    const [fieldSize, metaSize, helpersSize] = useField(names.fileSize);
    const [fieldType, metaType, helpersType] = useField(names.fileType);

    const fileSizeTooLow = fieldSize.value != undefined && fieldSize.value < 1024;

    const filetypesAccepted = ["application/pdf", "image/png", "image/jpeg", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    return (<GroupSetCustom {...props}>
        <Dropzone onDrop={acceptedFiles => {

            if (acceptedFiles.length == 1) {
                let file = acceptedFiles[0];
                helpersBinary.setValue(file);
                helpersName.setValue(file.name);
                helpersSize.setValue(file.size);
                helpersType.setValue(file.type);
            }
        }}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()} className="dropzone-files">
                        <input {...getInputProps()} />
                        <div className="dropzone-message">
                            <i className="fas fa-cloud-upload-alt"></i>
                            <div>
                                Drag and drop a file from an open Explorer window,<br />
                                or click within this grey box to browse for a file.
                            </div>
                        </div>
                        <div>
                            <b>{fieldName.value}</b>
                        </div>
                        {(fieldName.value || '').length > 0 && <div>
                            size {bytesToFriendly(fieldSize.value)} {fileSizeTooLow && <span>warning file size too low</span>}
                        </div>}
                        {fieldType.value && filetypesAccepted.indexOf(fieldType.value) < 0 && <span className="red">Please only upload Images, PDFs or Word documents</span>}
                    </div>
                </section>
            )}
        </Dropzone>
    </GroupSetCustom>);
}