import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { LabeledDropdown, LabeledDropdownProps } from '../controls/LabeledDropdown';
import { useContext } from 'react';

import { DrugFormulation } from '../../models/DrugFormulation';

export function GroupSetLabeledDropdown<T>(props: GroupSetProps & { dropdownProps: LabeledDropdownProps<T> }) {

    const [field, meta, helpers] = useField(props.fieldName);

    const originalOnChange = props.dropdownProps.onSelectedItemChanged;

    return (<GroupSetCustom
        title={props.title}
        fieldName={props.fieldName}
        controlSize={props.controlSize}
        required={props.required}
    >
        <LabeledDropdown
            {...props.dropdownProps}
            selectedValue={field.value}
            onSelectedItemChanged={(item) => {

                helpers.setValue(typeof item !== 'undefined' ? props.dropdownProps.getId(item as DrugFormulation) : undefined)
                if (originalOnChange)
                    originalOnChange(item);
            }}
            disabled={props.disabled}
        />

    </GroupSetCustom>)
}

