import { useReducer, useEffect, useRef } from 'react';
import GenericListEditor from './GenericListEditor';
import { PersonalProtectiveEquipment, ValidationSchema } from '../../models/PersonalProtectiveEquipment';
import { PersonalProtectiveEquipmentService } from '../../services/PersonalProtectiveEquipmentService';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { parseClassName } from 'react-toastify/dist/utils';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';


export const PersonalProtectiveEquipmentListEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <div className="mb-2 text-end"><a href="/admin">Return to Admin</a></div>
        <GenericListEditor<PersonalProtectiveEquipment, {}>
        colSpan={4}
        filter={{}}
        getKey={x => x.id.toString()}
        search={(filter, offset, pageSize) => PersonalProtectiveEquipmentService.search(offset, pageSize)}
        tableHead={<tr>
            <th>#</th>
            <th>Drug Name</th>
            <th>Icon</th>
            <th>Active</th> 
            <th>Actions</th>
        </tr>}
        tableBodyRow={(item, index) => <tr key={item.id.toString()}>
            <td>{index + 1}</td>
            <td>{item.title}</td>
            <td><img src={`/api/personalprotectiveequipment/getIcon/${item.id}/${item.fileName}`} className="img-fluid" /></td>
            <td>{item.isActive ? "Yes" : "No"}</td>
            <th> <NavLink tag={Link} to={`/ppe-edit/${item.id}`} >edit</NavLink></th>
        </tr>}
        addNewRoute={"/ppe-add"}
        />
    </>;
}