import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { Drug, DrugGroup } from '../models';
import { CommunityFacility } from "../models/CommunityFacility";
import { HDMapView } from "../models/HDMapView";
import { HDMap } from "../models/HDMap";

export class SearchIn extends Object {
    communityFacility: CommunityFacility;
    drugGroupId: number;
    drugId: number;
    drugFormulationId: number;
    taskTypeId: number;
}

export class HDMapService extends ServiceBase {

    public static async save(dto: HDMap): Promise<Result<HDMap>> {

        var result = await this.requestJson<HDMap>({
            url: `api/hdmap/save`,
            method: "POST",
            data: dto
        });

        return result;
    }


    public static async delete(dto: HDMap): Promise<Result<Drug>> {

        var result = await this.requestJson<Drug>({
            url: `api/hdmap/delete`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async get(id: number): Promise<Result<HDMap>> {

        var result = await this.requestJson<HDMap>({
            url: `api/hdmap/get/${id}`,
            method: "GET"
        });

        return result;
    }

    public static async search(offset: number, pageSize: number, showAll: boolean): Promise<Result<PagedResult<HDMapView>>> {

        var result = await this.requestJson<PagedResult<HDMapView>>({
            url: `api/hdmap/search`,
            method: "POST",
            data: { offset, pageSize, showAll }
        });

        return result;
    }

    public static async findSingle(searchIn: SearchIn): Promise<Result<HDMapView>> {

        var result = await this.requestJson<HDMapView>({
            url: `api/hdmap/findSingle`,
            method: "POST",
            data: searchIn
        });

        return result;
    }
}
