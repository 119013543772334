import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { GenericDropdown, GenericDropdownProps } from '../controls/GenericDropdown';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useContext, useEffect, useId, useRef, useState } from 'react';
import { Result } from '../../services/ServiceBase';


export function GroupSetDropdownMultiSelectAsync<T, K>(props: GroupSetProps & {
    dropdownProps: {
        loadDataSource: () => Promise<Result<T[]>>;
        getKey: (item: T) => string,
        renderItem: (item: T) => React.ReactNode | string,
        noSelctionText: React.ReactNode | string,
        isChecked: (item: T, selectedDataSource: K[]) => boolean,
        //renderSelected: (selectedDataSource: K[]) => React.ReactNode | string,
        createSelectedItem: (item: T) => K,
        removeSelectedItem: (values: K[], item: T) => K[],
        findBySelectedId: (items: T[], selected: K) => T,

    }
}) {
    const mountRed = useRef(true);
    const [field, meta, helpers] = useField<K[]>(props.fieldName);
    const [ready, setReady] = useState(false);
    const [dataSource, setDataSource] = useState<T[]>([]);
    const checkBoxBaseId = useId();

    const trimText = (text: string): string => {
        if ((text || '').length > 20)
            return text.substr(0, 18) + '..';

        return text;
    }

    const loadDataSource = () => {
        //setIsLoading(true);

        console.log('###loadDataSource multi async');

        props.dropdownProps.loadDataSource().then(x => {

            if (!mountRed.current)
                return;

            if (!x.hasErrors) {

                /*let ds = (props.preprocessDataSource)
                    ? props.preprocessDataSource(x.value)
                    : x.value;*/

                setDataSource(x.value);
            }

            setReady(true);
            //setIsLoading(false);
        });
    }

    useEffect(() => {
        loadDataSource();

        // keep track of mounted state
        return (() => {
            mountRed.current = false;
        })

    }, []);

    //console.log('GroupSetDropdownMultiSelectAsync field value', field);

    return (<GroupSetCustom
        title={props.title}
        fieldName={props.fieldName}
        controlSize={props.controlSize}
        required={props.required}
    >
        <UncontrolledDropdown>
            <DropdownToggle caret>
                {
                    ready && <>{field.value.length > 0
                        ? trimText(field.value.map(x => props.dropdownProps.renderItem(props.dropdownProps.findBySelectedId(dataSource, x))).join(', '))
                        : props.dropdownProps.noSelctionText}
                    </>
                }
            </DropdownToggle>
            <DropdownMenu>
                {ready && dataSource.map(x => <li key={props.dropdownProps.getKey(x)} className="dropdown-item">
                    <input type="checkbox" id={checkBoxBaseId + props.dropdownProps.getKey(x)} className="form-check-input"
                        /*onChange={(e) => this.props.selectChange(cp, e.target.checked)}*/
                        onChange={(e) => {
                            if (e.target.checked) {
                                var newItem = props.dropdownProps.createSelectedItem(x);

                                var newSelectedIds = field.value.concat([newItem]);
                                helpers.setValue(newSelectedIds, true);

                            } else {

                                var newSelectedIds = props.dropdownProps.removeSelectedItem(field.value, x);
                                helpers.setValue(newSelectedIds, true);
                            }

                            if (props.onValueChanged)
                                props.onValueChanged();
                        }}
                        checked={props.dropdownProps.isChecked(x, field.value)}
                    />
                    <label className="ms-1" htmlFor={checkBoxBaseId + props.dropdownProps.getKey(x)}>{props.dropdownProps.renderItem(x)}</label>
                </li>)}
            </DropdownMenu>
        </UncontrolledDropdown>
        {(ready && field.value.length > 0) && <small>{field.value.map(x => props.dropdownProps.renderItem(props.dropdownProps.findBySelectedId(dataSource, x))).join(', ')}</small>}
    </GroupSetCustom>)
}

