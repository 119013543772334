import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { DrugGroup } from '../models';


export class DrugGroupService extends ServiceBase {

    public static async get(id: number): Promise<Result<DrugGroup>> {

        var result = await this.requestJson<DrugGroup>({
            url: `api/drugGroup/get`,
            method: "POST",
            data: { id }
        });

        return result;
    }

    public static async list(): Promise<Result<DrugGroup[]>> {

        var result = await this.requestJson<DrugGroup[]>({
            url: `api/drugGroup/list`,
            method: "GET"
        });

        return result;
    }
}
