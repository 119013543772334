import { useReducer, useEffect, useRef } from 'react';
import GenericEditor from './GenericEditor';
import { MediaLibraryEditorFields } from '../editorFields/MediaLibraryEditorFields';
import { MediaLibrary, ValidationSchema } from '../../models/MediaLibrary';
import { MediaLibraryService } from '../../services/MediaLibraryService';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';


export const MediaLibraryEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <GenericEditor<MediaLibrary, number>
        createNewModel={() => { return new MediaLibrary() }}
        getById={id => MediaLibraryService.get(id)}
        save={model => MediaLibraryService.save(model)}
        formikFields={MediaLibraryEditorFields}
        validationSchema={ValidationSchema}
        stringToId={x => parseInt(x, 10)}
        getId={x => x.mediaLibraryId}
        shouldTryToLoad={id => id > 0}
        routes={{
            list: '/medialibrary-list'
        }}
    />;
}