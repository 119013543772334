import * as yup from 'yup';


export class SiteSettings {
    siteSettingsId: number;
    homePageFileName: string;
    homePageFileSize: number;
    homePageFileType: string;
    homePageFileBinary: File;
    homePageText: string;
    resourceCommLinkText1: string;
    resourceCommLinkUrl1: string;
    resourceCommLinkDesc1: string;
    resourceCommLinkText2: string;
    resourceCommLinkUrl2: string;
    resourceCommLinkDesc2: string;
    resourceCommLinkText3: string;
    resourceCommLinkUrl3: string;
    resourceCommLinkDesc3: string;
    resourceCommLinkText4: string;
    resourceCommLinkUrl4: string;
    resourceCommLinkDesc4: string;
    resourceCommLinkText5: string;
    resourceCommLinkUrl5: string;
    resourceCommLinkDesc5: string;
    resourceCommLinkText6: string;
    resourceCommLinkUrl6: string;
    resourceCommLinkDesc6: string;
    resourceFacLinkText1: string;
    resourceFacLinkUrl1: string;
    resourceFacLinkDesc1: string;
    resourceFacLinkText2: string;
    resourceFacLinkUrl2: string;
    resourceFacLinkDesc2: string;
    resourceFacLinkText3: string;
    resourceFacLinkUrl3: string;
    resourceFacLinkDesc3: string;
    resourceFacLinkText4: string;
    resourceFacLinkUrl4: string;
    resourceFacLinkDesc4: string;
    resourceFacLinkText5: string;
    resourceFacLinkUrl5: string;
    resourceFacLinkDesc5: string;
    resourceFacLinkText6: string;
    resourceFacLinkUrl6: string;
    resourceFacLinkDesc6: string;

    resourceCommLinkMediaLibraryId1: number;
    resourceCommLinkMediaLibraryId2: number;
    resourceCommLinkMediaLibraryId3: number;
    resourceCommLinkMediaLibraryId4: number;
    resourceCommLinkMediaLibraryId5: number;
    resourceCommLinkMediaLibraryId6: number;
    resourceFacLinkMediaLibraryId1: number;
    resourceFacLinkMediaLibraryId2: number;
    resourceFacLinkMediaLibraryId3: number;
    resourceFacLinkMediaLibraryId4: number;
    resourceFacLinkMediaLibraryId5: number;
    resourceFacLinkMediaLibraryId6: number;

    liabilityMessage: string;

    constructor() {
        this.siteSettingsId = 0;
        this.homePageFileName = '';
        this.homePageFileSize = 0;
        this.homePageText = '';
        this.resourceCommLinkText1 = '';
        this.resourceCommLinkUrl1 = '';
        this.resourceCommLinkDesc1 = '';
        this.resourceCommLinkText2 = '';
        this.resourceCommLinkUrl2 = '';
        this.resourceCommLinkDesc2 = '';
        this.resourceCommLinkText3 = '';
        this.resourceCommLinkUrl3 = '';
        this.resourceCommLinkDesc3 = '';
        this.resourceCommLinkText4 = '';
        this.resourceCommLinkUrl4 = '';
        this.resourceCommLinkDesc4 = '';
        this.resourceCommLinkText5 = '';
        this.resourceCommLinkUrl5 = '';
        this.resourceCommLinkDesc5 = '';
        this.resourceCommLinkText6 = '';
        this.resourceCommLinkUrl6 = '';
        this.resourceCommLinkDesc6 = '';
        this.resourceFacLinkText1 = '';
        this.resourceFacLinkUrl1 = '';
        this.resourceFacLinkDesc1 = '';
        this.resourceFacLinkText2 = '';
        this.resourceFacLinkUrl2 = '';
        this.resourceFacLinkDesc2 = '';
        this.resourceFacLinkText3 = '';
        this.resourceFacLinkUrl3 = '';
        this.resourceFacLinkDesc3 = '';
        this.resourceFacLinkText4 = '';
        this.resourceFacLinkUrl4 = '';
        this.resourceFacLinkDesc4 = '';
        this.resourceFacLinkText5 = '';
        this.resourceFacLinkUrl5 = '';
        this.resourceFacLinkDesc5 = '';
        this.resourceFacLinkText6 = '';
        this.resourceFacLinkUrl6 = '';
        this.resourceFacLinkDesc6 = '';

        this.resourceCommLinkMediaLibraryId1 = 0;
        this.resourceCommLinkMediaLibraryId2 = 0;
        this.resourceCommLinkMediaLibraryId3 = 0;
        this.resourceCommLinkMediaLibraryId4 = 0;
        this.resourceCommLinkMediaLibraryId5 = 0;
        this.resourceCommLinkMediaLibraryId6 = 0;
        this.resourceFacLinkMediaLibraryId1 = 0;
        this.resourceFacLinkMediaLibraryId2 = 0;
        this.resourceFacLinkMediaLibraryId3 = 0;
        this.resourceFacLinkMediaLibraryId4 = 0;
        this.resourceFacLinkMediaLibraryId5 = 0;
        this.resourceFacLinkMediaLibraryId6 = 0;

        this.liabilityMessage = '';
    }
}

export const ValidationSchema = yup.object().shape({
    homePageText: yup.string().required('Required')    
});

