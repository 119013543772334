import { useReducer, useEffect, useRef } from 'react';
import GenericListEditor from './GenericListEditor';
import { HDAdmin } from '../../models/index';
import { HDAdminService } from '../../services/HDAdminService';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import Cookies from 'js-cookie';

export const HDAdminListEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminIsMaster) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <div className="mb-2 text-end"><a href="/admin">Return to Admin</a></div>
        <GenericListEditor<HDAdmin, {}>
            colSpan={4}
            filter={{showAll: true}}
            getKey={x => x.adminUserId.toString()}
            search={(filter, offset, pageSize) => HDAdminService.search(offset, pageSize)}
            tableHead={<tr>
                <th>#</th>   
                <th>Username</th>
                <th>Is Administrator</th> 
                <th>Deactivated</th> 
                <th>Actions</th>
            </tr>}
            tableBodyRow={(item, index) => <tr key={item.adminUserId.toString()}>
                <td>{index + 1}</td>
                <td>{item.adminUserName}</td>
                <td>{item.adminIsMaster ? "Yes" : "No"}</td>
                <td>{item.isInactive ? "Yes" : "No"}</td>
                <td> <NavLink tag={Link} to={`/hdadmin-edit/${item.adminUserId}`} >edit</NavLink></td>
            </tr>}
            addNewRoute={"/hdadmin-add"}
        />
    </>;
}