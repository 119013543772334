import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { ToastContainer } from "react-toastify";

export class Layout extends Component<any, any> {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />        
            <Container tag="main">
                <Row>
                    <Col>
                        {this.props.children}
                    </Col>
                </Row>
            </Container>
            <ToastContainer position='top-right' />
            <Container>
                <Row className="footer-logos mt-4">
                    <Col md={3}><img src={require('../images/logos/FraserHealth_Logo_Rectangle_NoSlogan_Colour.png')} alt="ShielD" className="logo" /></Col>
                    <Col md={3}><img src={require('../images/logos/NH_logo_contact_workplacehealthandsafety@northernhealth.ca.png')} alt="ShielD" className="logo" /></Col>
                    <Col md={3}><img src={require('../images/logos/fnha_logo_contact_SafetyMatters@fnha.ca.png')} alt="ShielD" className="logo" /></Col>
                    <Col md={3}><img src={require('../images/logos/VCH_logo_contact_peoplesafety@vch.ca.png')} alt="ShielD" className="logo" /></Col>
                </Row>
            </Container>
      </div>
    );
  }
}
