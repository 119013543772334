import { useReducer, useEffect, useRef } from 'react';
import GenericEditor from './GenericEditor';
import { HelpInfoEditorFields } from '../editorFields/HelpInfoEditorFields';
import { HelpInfo, ValidationSchema } from '../../models/HelpInfo';
import { HelpInfoService } from '../../services/HelpInfoService';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';

export const HelpInfoEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <div className="mb-2 text-end"><a href="/admin">Return to Admin</a></div>
        <GenericEditor<HelpInfo, number>
            createNewModel={() => { return new HelpInfo() }}
            getById={id => HelpInfoService.get(x => id)}
            save={model => HelpInfoService.save(model)}
            formikFields={HelpInfoEditorFields}
            validationSchema={ValidationSchema}
            stringToId={x => parseInt(x, 10)}
            getId={x => 1}
            shouldTryToLoad={id => id > 0}
        />
        </>;
}