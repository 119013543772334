﻿import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { HDAdmin } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";
import * as Models from '../../models';

export function HDAdminEditorFields(props: FormikProps<HDAdmin>) {

    return (<Row>
        <Col sm="12" md="8">
            <div className="form-section">

                <FormUtils.GroupSetText
                    fieldName="adminUserName"
                    title="Username"
                    required
                />

                <FormUtils.GroupSetCheckbox
                    fieldName="adminIsMaster"
                    title="Is Administrator"
                />

                <FormUtils.GroupSetPassword
                    fieldName="newAdminUserPassword"
                    title="Password"
                    required
                />

                <FormUtils.GroupSetCheckbox
                    fieldName="isInactive"
                    title="Deactivated"
                />
            </div>

        </Col>
    </Row>);
}