﻿import * as yup from 'yup';


export class MediaLibrary {
    mediaLibraryId: number;
    mediaLibraryName: string;
    mediaLibraryFileName: string;
    mediaLibraryFileBinary: File;
    mediaLibraryFileSize: number;
    mediaLibraryFileType: string;
    mediaLibraryOrder: number;
    isInactive: boolean;

    constructor() {
        this.mediaLibraryId = 0;
        this.mediaLibraryName = '';        
        this.mediaLibraryFileName = '';     
        this.mediaLibraryFileSize = 0;
        this.mediaLibraryFileType = '';
        this.mediaLibraryOrder = 0;
        this.isInactive = false;
    }
}

export const ValidationSchema = yup.object().shape({
    mediaLibraryName: yup.string().required('Required'),
    mediaLibraryOrder: yup.string().required('Required'),
});

