import { DrugMapSearch } from './components/DrugMapSearch/DrugMapSearch';
import { Resources } from './components/Resources';
import { Help } from './components/Help';
import * as Editors from "./components/editors/index";
import { Home } from './components/Home';
import { Admin } from './components/Admin';
import { Logout } from './components/Logout';
import { HelpInfo } from './models/HelpInfo';

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    // #region drugs
    {
        path: '/search',
        element: <DrugMapSearch />
    },
    {
        path: '/resources',
        element: <><h1>Resources</h1>
            <Resources />
        </>
    },
    {
        path: '/help',
        element: <><h1>Help</h1>
            <Help />
        </>
    },
    {
        path: '/drugs-list',
        element: <><h1>Drug List</h1>
            <Editors.DrugListEditor />
        </>
    },
    {
        path: '/drugs-edit/:id',
        element: <><h1>Drug Edit</h1>
            <Editors.DrugEditor />
        </>
    },
    {
        path: '/drugs-add',
        element: <><h1>Drug Add</h1>
            <Editors.DrugEditor />
        </>
    },
    // #endregion
    // #region protective equipment
    {
        path: '/ppe-list',
        element: <><h1>Personal Protective Equipment List</h1>
            <Editors.PersonalProtectiveEquipmentListEditor />
        </>
    },
    {
        path: '/ppe-add',
        element: <><h1>Personal Protective Equipment Add</h1>
            <Editors.PersonalProtectiveEquipmentEditor />
        </>
    },
    {
        path: '/ppe-edit/:id',
        element: <><h1>Personal Protective Equipment Edit</h1>
            <Editors.PersonalProtectiveEquipmentEditor />
        </>
    },
    // #endregion

    // #region drug formulations
    {
        path: '/drug-formulations-add',
        element: <><h1>Drugs Formulations Add</h1>
            <Editors.DrugFormulationEditor />
        </>
    },
    {
        path: '/drug-formulations-list',
        element: <><h1>Drugs Formulations List</h1>
            <Editors.DrugFormulationListEditor />
        </>
    },
    {
        path: '/drug-formulations-edit/:id',
        element: <><h1>Drugs Formulations Edit</h1>
            <Editors.DrugFormulationEditor />
        </>
    },
    // #endregion

    // #region task types
    {
        path: '/task-type-list',
        element: <>
            <h1>Task Type List</h1>
            <Editors.TaskTypeListEditor />
        </>
    },
    {
        path: '/task-type-edit/:id',
        element: <>
            <h1>Task Type Edit</h1>
            <Editors.TaskTypeEditor />
        </>
    },
    {
        path: '/task-type-add',
        element: <><h1>Task Type Add</h1>
            <Editors.TaskTypeEditor />
        </>
    },
    // #endregion

    // #region hd map
    {
        path: '/hd-map-add',
        element: <><h1>HD Map Add</h1>
            <Editors.HDMapEditor />
        </>
    },
    {
        path: '/hd-maps-list',
        element: <><h1>HD Map List</h1>
            <Editors.HDMapListEditor />
        </>
    },
    {
        path: '/hd-map-edit/:id',
        element: <><h1>HD Map Edit</h1>
            <Editors.HDMapEditor />
        </>
    },
    // #endregion

    // #region medialibrary
    {
        path: '/medialibrary-add',
        element: <><h1>Media Library Add</h1>
            <Editors.MediaLibraryEditor />
        </>
    },
    {
        path: '/medialibrary-list',
        element: <><h1>Media Library List</h1>
            <Editors.MediaLibraryListEditor />
        </>
    },
    {
        path: '/medialibrary-edit/:id',
        element: <><h1>Media Library Edit</h1>
            <Editors.MediaLibraryEditor />
        </>
    },
    // #endregion

    // #region site settings
    {
        path: '/sitesettings/:id',
        element: <><h1>Site Settings</h1>
            <Editors.SiteSettingsEditor />
        </>
    },
    // #endregion

    // #region help info
    {
        path: '/helpinfo/:id',
        element: <><h1>Help Page</h1>
            <Editors.HelpInfoEditor />
        </>
    },
    // #endregion

    // #region HD Admins
    {
        path: '/hdadmin-add',
        element: <><h1>Admin Add</h1>
            <Editors.HDAdminEditor />
        </>
    },
    {
        path: '/hdadmin-list',
        element: <><h1>Admin List</h1>
            <Editors.HDAdminListEditor />
        </>
    },
    {
        path: '/hdadmin-edit/:id',
        element: <><h1>Admin Edit</h1>
            <Editors.HDAdminEditor />
        </>
    },
    // #endregion

    // #region admin
    {
        path: '/admin',
        element: <Admin />
    },
    // #endregion

    // #region admin
    {
        path: '/logout',
        element: <Logout />
    }
    // #endregion

];

export default AppRoutes;
