import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { HDAdmin } from "../models/HDAdmin";
import { Login } from "../models/Login";

export class HDAdminService extends ServiceBase {

    public static async save(dto: HDAdmin): Promise<Result<HDAdmin>> {

        var result = await this.requestJson<HDAdmin>({
            url: `api/hdadmin/save`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async delete(dto: HDAdmin): Promise<Result<HDAdmin>> {

        var result = await this.requestJson<HDAdmin>({
            url: `api/hdadmin/delete`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async get(id: number): Promise<Result<HDAdmin>> {

        var result = await this.requestJson<HDAdmin>({
            url: `api/hdadmin/get/${id}`,
            method: "GET"
        });

        return result;
    }

    public static async search(offset: number, pageSize: number): Promise<Result<PagedResult<HDAdmin>>> {

        var result = await this.requestJson<PagedResult<HDAdmin>>({
            url: `api/hdadmin/search`,
            method: "POST",
            data: { offset, pageSize }
        });

        return result;
    }

    public static async list(): Promise<Result<PagedResult<HDAdmin>>> {

        var result = await this.requestJson<PagedResult<HDAdmin>>({
            url: `api/hdadmin/list`,
            method: "GET"
        });

        return result;
    }

    public static async auth(sessionGuid): Promise<Result<HDAdmin>> {

        var result = await this.requestJson<HDAdmin>({
            url: `api/hdadmin/auth`,
            method: "POST",
            data: { "AdminSessionGuid": sessionGuid }
        });

        return result;
    }

    public static async login(dto: Login): Promise<Result<HDAdmin>> {

        var result = await this.requestJson<HDAdmin>({
            url: `api/hdadmin/login`,
            method: "POST",
            data: { "Username": dto.UserName, "Password": dto.Password }
        });

        return result;
    }

    public static async logout(): Promise<Result<boolean>> {

        var result = await this.requestJson<boolean>({
            url: `api/hdadmin/logout`,
            method: "GET"
        });

        return result;
    }

    public 
}
