import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { DrugGroup, HDMap } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";
import * as Models from '../../models';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const dsCcommunityFaicility = [Models.CommunityFacility.Community, Models.CommunityFacility.Facility];

export function HDMapEditorFields(props: FormikProps<HDMap>) {

    const handlePrimaryChange = (html) => {
        props.setFieldValue('primaryMessage', html);
    }

    const handleSecondaryChange = (html) => {
        props.setFieldValue('secondaryMessage', html);
    }

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
    /* 
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    return (<Row>
        <Col sm="12" md="8">
            <div className="form-section">

                <FormUtils.GroupSetDropdown<Models.CommunityFacility>
                    title="Community / Facility"
                    fieldName="communityFacility"
                    dropdownProps={{
                        dataSource: dsCcommunityFaicility,
                        getId: x => x,
                        renderItem: x => Models.CommunityFacility[x],
                        renderSelectedItem: x => Models.CommunityFacility[x]
                    }}
                />



                <FormUtils.GroupSetDropdownAsync<DrugGroup, number>
                    fieldName="drugGroupId"
                    title="Group"
                    dropdownProps={
                        {
                            getId: x => x.id,
                            loadDataSource: () => Services.DrugGroupService.list(),
                            renderSelectedItem: x => <>{x.groupName} <div className={"ms-2 group-theme-bg-" + x.groupColor.toLowerCase()} style={{ height: '22px', width: '22px' }}></div></>,
                            renderItem: x => <div className="d-flex">{x.groupName} <div className={"ms-2 group-theme-bg-" + x.groupColor.toLowerCase()} style={{ height: '22px', width: '22px' }}></div></div>,
                            dropdownToggleClass: 'd-flex align-items-center'
                        }
                    }
                />

                <FormUtils.GroupSetDropdownAsync<Models.TaskType, number>
                    fieldName="taskTypeId"
                    title="Task Type"
                    dropdownProps={
                        {
                            getId: x => x.id,
                            loadDataSource: () => Services.TaskTypeService.list(false),
                            renderSelectedItem: x => x.taskName,
                            renderItem: x => x.taskName
                        }
                    }
                />

                <FormUtils.GroupSetDropdownAsync<Models.DrugFormulation, number>
                    fieldName="drugFormulationId"
                    title="Drug Formulation"
                    dropdownProps={
                        {
                            getId: x => x.id,
                            loadDataSource: () => Services.DrugFormulationService.list(false),
                            renderSelectedItem: x => x.name,
                            renderItem: x => x.name
                        }
                    }
                />

                <div className="form-group mb-4">
                    <label className="form-label lead ">Primary Message</label>
                    <ReactQuill
                        theme="snow"
                        onChange={handlePrimaryChange}
                        value={props.values.primaryMessage}
                        modules={modules}
                        formats={formats}
                        bounds={'.app'}
                        placeholder="Edit Text"
                    />
                </div>

                <div className="form-group mb-4">
                    <label className="form-label lead ">Secondary Message</label>
                    <ReactQuill
                        theme="snow"
                        onChange={handleSecondaryChange}
                        value={props.values.secondaryMessage}
                        modules={modules}
                        formats={formats}
                        bounds={'.app'}
                        placeholder="Edit Text"
                    />
                </div>

                <FormUtils.GroupSetModalMultiSelect<Models.PersonalProtectiveEquipment, number>
                    fieldName="personalProtectiveEquipmentIds"
                    title="Personal Protective Equipment"
                    modalProps={{ title: "Select Personal Protective Equipment" }}
                    selectedItemView={(id) => <PpeByID id={id}></PpeByID>}
                    listAll={() => Services.PersonalProtectiveEquipmentService.list(false)}
                    numberOfColumns={4}
                    getId={(item) => item.id}
                    tableHead={<tr>
                        <th>#</th>
                        <th>PPE</th>
                        <th>Icon</th>
                        <th>Actions</th>
                    </tr>}
                    rowView={(item, index) => <PPERowView key={item.id.toString()} index={index} item={item}
                        selected={(props.values.personalProtectiveEquipmentIds || []).indexOf(item.id) > -1}
                        onSelect={(id) => {
                            let updatedValues = (props.values.personalProtectiveEquipmentIds || [])
                                .filter(x => x != id) // just make sure we don't insert duplicates'
                                .concat([id]);
                            props.setFieldValue('personalProtectiveEquipmentIds', updatedValues);
                        }}
                        onDeselect={(id) => {

                            let updatedValues = (props.values.personalProtectiveEquipmentIds || [])
                                .filter(x => x != id);
                            props.setFieldValue('personalProtectiveEquipmentIds', updatedValues);

                        }}
                    />}

                />

                <FormUtils.GroupSetCheckbox
                    fieldName="isActive"
                    title="Active"
                />

            </div>

        </Col>
    </Row>);
}

function PPERowView(props: {
    item: Models.PersonalProtectiveEquipment,
    index: number,
    selected: boolean,
    onSelect: (id: number) => void,
    onDeselect: (id: number) => void

}) {
    return <tr key={props.item.id.toString()}><td>{props.index + 1}</td><td>{props.item.title}</td>
        <td><img src={`api/PersonalProtectiveEquipment/getIcon/${props.item.id}/${props.item.fileName}`} className="img-fluid" style={{ maxHeight: '50px' }} /></td>
        <td>
            {props.selected ?
                <Button color="primary" outline onClick={(e) => { props.onDeselect(props.item.id) }}>Deselect</Button>
                :
                <Button color="primary" onClick={(e) => { props.onSelect(props.item.id) }}>Select</Button>
            }
        </td>
    </tr>
}

function PpeByID(props: { id: number }) {

    const mountRed = React.useRef(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [item, setItem] = React.useState<Models.PersonalProtectiveEquipment>();

    const loadDataSource = async () => {

        setIsLoading(true);

        var itemResult = await Services.PersonalProtectiveEquipmentService.get(props.id);

        if (!itemResult.hasErrors) {
            setIsLoading(false);
            setItem(itemResult.value);
        }
        else {
            setIsLoading(false);            
        }
    }


    React.useEffect(() => {

        loadDataSource();

    }, []);

    return <div>{item && <>{item.title} <img src={`api/PersonalProtectiveEquipment/getIcon/${item.id}/${item.fileName}`} className="img-fluid" style={{ maxHeight:'50px' }} /></>}</div>
}
