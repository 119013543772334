import * as yup from 'yup';
import { DrugGroup } from './DrugGroup';


export class Drug {
    id: number;
    drugName: string;
    drugGroupId: number;
    drugGroup: DrugGroup;
    drugOrder: number;
    isActive: boolean;

    constructor() {
        this.id = 0;
        this.drugName = '';
        this.drugOrder = 2;
        this.isActive = true
    }
}

export const ValidationSchema = yup.object().shape({
    drugName: yup.string().required('Required'),
    drugGroupId: yup.number().required('Required')
});

