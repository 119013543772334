﻿import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { MediaLibrary } from "../../models/index";
import * as Services from '../../services/index';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";


export function MediaLibraryEditorFields(props: FormikProps<MediaLibrary>) {

    //const values = props.values as Drug;

    //const isReadOnly = false;

    return (<Row>
        <Col sm="6" md="4">
            <div className="form-section">

                <FormUtils.GroupSetText
                    fieldName="mediaLibraryName"
                    title="Name"
                    required
                />

                <FormUtils.GroupSetFileUpload
                    fieldName="mediaLibraryFileBinary"
                    title="File"
                    controlSize="large"
                    names={{
                        fileName: 'mediaLibraryFileName',
                        fileBinary: 'mediaLibraryFileBinary',
                        fileSize: 'mediaLibraryFileSize',
                        fileType: 'mediaLibraryFileType',
                    }}
                    required
                />

                <FormUtils.GroupSetText
                    fieldName="mediaLibraryOrder"
                    title="Order"
                    required
                />

                <FormUtils.GroupSetCheckbox
                    fieldName="isInactive"
                    title="Deactivated"
                />

            </div>

        </Col>
    </Row>);
}

