import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useReducer, useEffect, useRef} from 'react';
import { CustomPlaceholder } from 'react-placeholder-image';

import {
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

import * as Models from '../../models';
import * as Services from '../../services';
import { DrugMapSearchFilters } from './DrugMapSearchFilters';
import { HDMapService, SearchIn } from '../../services/HDMapService';
import { SiteSettingsService } from '../../services/SiteSettingsService';

type State = {
    hdMap?: Models.HDMapView;
    isLoading: boolean;
    selectedCommunityFacility?: Models.CommunityFacility;
    selectedDrug?: Models.Drug;
    selectedTaskType?: Models.TaskType;
    selectedDrugFormulation?: Models.DrugFormulation;
    currentFilter?: SearchIn;
    dsCommunityFacility: Models.CommunityFacility[];
    dsDrugs: Models.Drug[];
    dsDrugFormulations: Models.DrugFormulation[];
    dsTaskType: Models.TaskType[];
    siteSettings?: Models.SiteSettings;
}

export const DrugMapSearch = () => {

    const mountRef = useRef(true);

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            hdMap: undefined,
            isLoading: false,
            selectedCommunityFacility: undefined,
            selectedDrug: undefined,
            selectedTaskType: undefined,
            selectedDrugFormulation: undefined,
            dsCommunityFacility: [],
            dsDrugs: [],
            dsDrugFormulations: [],
            dsTaskType: []
        }
    );

    const search = async (filter: SearchIn) => {
        setState({
            selectedCommunityFacility: filter.communityFacility,
            selectedDrug: state.dsDrugs.find((x) => x.id == filter.drugId),
            selectedDrugFormulation: state.dsDrugFormulations.find((x) => x.id == filter.drugFormulationId),
            selectedTaskType: state.dsTaskType.find((x) => x.id == filter.taskTypeId),
            isLoading: true
        });
        
        const result = await HDMapService.findSingle(filter);

        setState({
            hdMap: result.value,
            currentFilter: filter,
            isLoading: false
        });
    }

    const loadDsComunityFacilities = () => {
        setState({
            dsCommunityFacility: [Models.CommunityFacility.Community, Models.CommunityFacility.Facility]
        });
    }

    const loadDsDrugs = async () => {

        const result = await Services.DrugService.list(false);

        if (!result.hasErrors && mountRef.current) {
            setState({ dsDrugs: result.value })
        }
    }

    const loadDsDrugFormulations = async () => {
        const result = await Services.DrugFormulationService.list(false);

        if (!result.hasErrors && mountRef.current) {
            setState({ dsDrugFormulations: result.value })
        }
    }

    const loadDsTaskTypes = async () => {
        const result = await Services.TaskTypeService.list(false);

        if (!result.hasErrors && mountRef.current) {
            setState({ dsTaskType: result.value })
        }
    }

    const loadSiteSettings = async () => {

        const result = await SiteSettingsService.get(1);

        if (!result.hasErrors && mountRef.current) {
            setState({ siteSettings: result.value })
        }
    }

    const regexStripHtml = /(<([^>]+)>)/gi;

    // on component mount
    useEffect(() => {

        loadDsComunityFacilities();
        loadDsDrugs();
        loadDsDrugFormulations();
        loadDsTaskTypes();
        loadSiteSettings();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <DrugMapSearchFilters
            onFiltersChange={(filter) => { search(filter); }}
            dsCommunityFacility={state.dsCommunityFacility}
            dsDrugs={state.dsDrugs}
            dsDrugFormulations={state.dsDrugFormulations}
            dsTaskType={state.dsTaskType}
        />

        {state.currentFilter && <>
            <Row>
                <Col xs={6} sm={6} md={6} lg={3}>
                    <dl>
                        <dt>
                            Work Location
                        </dt>
                        <dd>
                            {state.selectedCommunityFacility != undefined && Models.CommunityFacility[state.selectedCommunityFacility]}
                        </dd>
                    </dl>
                </Col>
                <Col xs={6} sm={6} md={6} lg={3}>
                    <dl>
                        <dt>
                            Drug Name
                        </dt>
                        <dd>
                            {state.selectedDrug?.drugName}
                        </dd>
                    </dl>
                </Col>
                <Col xs={6} sm={6} md={6} lg={3}>
                    <dl>
                        <dt>
                            Task Type
                        </dt>
                        <dd>
                            {state.selectedTaskType?.taskName}
                        </dd>
                    </dl>
                </Col>
                <Col xs={6} sm={6} md={6} lg={3}>
                    <dl>
                        <dt>
                            Formulation/Hazard
                        </dt>
                        <dd>
                            {state.selectedDrugFormulation?.name}
                        </dd>

                    </dl>
                </Col>
            </Row>

            {state.hdMap && <>
                {state.hdMap?.primaryMessage && state.hdMap?.primaryMessage.replace(regexStripHtml, '') &&
                <UncontrolledAccordion defaultOpen={"1"} className={"mb-3 " + 'accordion-' + state.hdMap?.drugGroup.groupColor.toLowerCase()}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">{`${state.selectedTaskType?.taskName || ''} of ${state.hdMap?.drugGroup.groupName || ''} Hazardous Drugs`}</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <div className="quill-text" dangerouslySetInnerHTML={{ __html: state.hdMap?.primaryMessage || '' }}></div>
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
                }
                {state.hdMap?.secondaryMessage && state.hdMap?.secondaryMessage.replace(regexStripHtml, '') &&
                    <UncontrolledAccordion defaultOpen={"1"} className={"mb-3 " + 'accordion-' + state.hdMap?.drugGroup.groupColor.toLowerCase()}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">Additional Info</AccordionHeader>
                            <AccordionBody accordionId="1">
                                <div className="quill-text" dangerouslySetInnerHTML={{ __html: state.hdMap?.secondaryMessage || '' }}></div>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                }
                <UncontrolledAccordion defaultOpen={"1"} className={"mb-3 " + 'accordion-' + state.hdMap?.drugGroup.groupColor.toLowerCase()}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">Personal Protective Equipment Required</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <Row className="row-cols-1 row-cols-md-2">
                                {state.hdMap?.personalProtectiveEquipment?.map(ppe => 
                                    <Col>
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="flex-shrink-0">
                                                {/*<CustomPlaceholder width={96} height={82} className={'group-theme-border-' + state.hdMap.drugGroup.groupColor} />*/}
                                                {/*<img src={getPPEIconUrl(ppe.id)} alt="" className={'group-theme-border-' + state.hdMap?.drugGroup.groupColor.toLowerCase()} width={96} height={82} />*/}
                                                <img src={'/api/PersonalProtectiveEquipment/geticon/' + ppe.id + '/image'} alt="" className={'group-theme-border-' + state.hdMap?.drugGroup.groupColor.toLowerCase()} width={96} height={82} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                {ppe.title}
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>

                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
            </>}

            {!state.hdMap && <div className="card">
                <div className="card-body mb-4">
                    ShielD has not identified hazardous drugs precautions for the combination of 'Task Type' and 'Formulation/Hazard' you have selected. Try a new selection.
                </div>
            </div>}
        </>
        }

        <div className="alert alert-primary mt-4" dangerouslySetInnerHTML={{ __html: state.siteSettings?.liabilityMessage || '' }}></div>
    </>
}