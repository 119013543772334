import classnames from 'classnames';

import { useReducer, useEffect, useRef } from 'react';
import { TabContent, TabPane, Button, Col, Row, Nav, NavItem, NavLink } from 'reactstrap';

import * as Models from '../models';
import { HelpInfoService } from '../services/HelpInfoService';
import { SiteSettingsService } from '../services/SiteSettingsService';

type State = {
    helpInfo?: Models.HelpInfo;
    isLoading: boolean;
    siteSettings?: Models.SiteSettings;
}

export const Help = () => {

    const mountRef = useRef(true);

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            helpInfo: undefined,
            isLoading: false
        }
    );

    const loadHelp = async () => {

        const result = await HelpInfoService.get(1);

        if (!result.hasErrors && mountRef.current) {
            setState({ helpInfo: result.value })
        }
    }

    const loadSiteSettings = async () => {

        const result = await SiteSettingsService.get(1);

        if (!result.hasErrors && mountRef.current) {
            setState({ siteSettings: result.value })
        }
    }

    // on component mount
    useEffect(() => {

        loadHelp();
        loadSiteSettings();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return (
        <>
            <div className="quill-text">
                <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpText || '' }}></div>
            </div>

            <div className="mt-4 mb-4">
                {state.helpInfo?.helpLinkMediaLibraryId1 && <p><a href={'/api/medialibrary/getfile/' + state.helpInfo?.helpLinkMediaLibraryId1} target="_blank">{state.helpInfo?.helpLinkText1}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc1 || '' }}></div>
                </p>}
                {(state.helpInfo?.helpLinkMediaLibraryId1 == 0 || state.helpInfo?.helpLinkMediaLibraryId1 == null) && state.helpInfo?.helpLinkUrl1 && <p><a href={state.helpInfo?.helpLinkUrl1} target="_blank">{state.helpInfo?.helpLinkText1}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc1 || '' }}></div>
                </p>}

                {state.helpInfo?.helpLinkMediaLibraryId2 && <p><a href={'/api/medialibrary/getfile/' + state.helpInfo?.helpLinkMediaLibraryId2} target="_blank">{state.helpInfo?.helpLinkText2}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc2 || '' }}></div>
                </p>}
                {(state.helpInfo?.helpLinkMediaLibraryId2 == 0 || state.helpInfo?.helpLinkMediaLibraryId2 == null) && state.helpInfo?.helpLinkUrl2 && <p><a href={state.helpInfo?.helpLinkUrl2} target="_blank">{state.helpInfo?.helpLinkText2}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc2 || '' }}></div>
                </p>}

                {state.helpInfo?.helpLinkMediaLibraryId3 && <p><a href={'/api/medialibrary/getfile/' + state.helpInfo?.helpLinkMediaLibraryId3} target="_blank">{state.helpInfo?.helpLinkText3}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc3 || '' }}></div>
                </p>}
                {(state.helpInfo?.helpLinkMediaLibraryId3 == 0 || state.helpInfo?.helpLinkMediaLibraryId3 == null) && state.helpInfo?.helpLinkUrl3 && <p><a href={state.helpInfo?.helpLinkUrl3} target="_blank">{state.helpInfo?.helpLinkText3}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc3 || '' }}></div>
                </p>}

                {state.helpInfo?.helpLinkMediaLibraryId4 && <p><a href={'/api/medialibrary/getfile/' + state.helpInfo?.helpLinkMediaLibraryId4} target="_blank">{state.helpInfo?.helpLinkText4}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc4 || '' }}></div>
                </p>}
                {(state.helpInfo?.helpLinkMediaLibraryId4 == 0 || state.helpInfo?.helpLinkMediaLibraryId4 == null) && state.helpInfo?.helpLinkUrl4 && <p><a href={state.helpInfo?.helpLinkUrl4} target="_blank">{state.helpInfo?.helpLinkText4}</a><br />
                    <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpLinkDesc4 || '' }}></div>
                </p>}
            </div>

            <div className="quill-text">
                <div dangerouslySetInnerHTML={{ __html: state.helpInfo?.helpContacts || '' }}></div>
            </div>

            <div className="alert alert-primary mt-4" dangerouslySetInnerHTML={{ __html: state.siteSettings?.liabilityMessage || '' }}></div>

        </>
    );
}