import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { MediaLibrary } from '../models';


export class MediaLibraryService extends ServiceBase {

    public static async save(dto: MediaLibrary): Promise<Result<MediaLibrary>> {

        var result = await this.requestJson<MediaLibrary>({
            url: `api/MediaLibrary/save`,
            method: "POST",
            data: dto            
        }, undefined, true);

        return result;
    }

    public static async delete(dto: MediaLibrary): Promise<Result<MediaLibrary>> {

        var result = await this.requestJson<MediaLibrary>({
            url: `api/MediaLibrary/delete`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async get(id: number): Promise<Result<MediaLibrary>> {

        var result = await this.requestJson<MediaLibrary>({
            url: `api/MediaLibrary/get/${id}`,
            method: "GET"            
        });

        return result;
    }

    public static async search(offset: number, pageSize: number): Promise<Result<PagedResult<MediaLibrary>>> {

        var result = await this.requestJson<PagedResult<MediaLibrary>>({
            url: `api/MediaLibrary/search`,
            method: "POST",
            data: { offset, pageSize }
        });

        return result;
    }

    public static async list(showInactive: Boolean = false): Promise<Result<MediaLibrary[]>> {

        var result = await this.requestJson<MediaLibrary[]>({
            url: `api/MediaLibrary/list?showInactive=` + showInactive,
            method: "GET"
        });

        return result;
    }
}
