import { useReducer, useEffect, useRef } from 'react';
import GenericListEditor from './GenericListEditor';
import { MediaLibrary, ValidationSchema } from '../../models/MediaLibrary';
import { MediaLibraryService } from '../../services/MediaLibraryService';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { parseClassName } from 'react-toastify/dist/utils';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';


export const MediaLibraryListEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <div className="mb-2 text-end"><a href="/admin">Return to Admin</a></div>
        <GenericListEditor<MediaLibrary, {}>
        colSpan={4}
        filter={{}}
        getKey={x => x.mediaLibraryId.toString()}
        search={(filter, offset, pageSize) => MediaLibraryService.search(offset, pageSize)}
        tableHead={<tr>
            <th>#</th>
            <th>Media Library Name</th>
            <th>File Name</th>
            <th>Deactivated</th>
            <th>Actions</th>
        </tr>}
        tableBodyRow={(item, index) => <tr key={item.mediaLibraryId.toString()}>
            <td>{index + 1}</td>
            <td>{item.mediaLibraryName}</td>
            <td>{item.mediaLibraryFileName}</td>
            <td>{item.isInactive ? "Yes" : "No"}</td>
            <th> <NavLink tag={Link} to={`/medialibrary-edit/${item.mediaLibraryId}`} >edit</NavLink></th>
        </tr>}
        addNewRoute={"/medialibrary-add"}
        />
    </>;
}