import { useReducer, useEffect, useRef } from 'react';
import GenericListEditor from './GenericListEditor';
import { HDMapView } from '../../models/index';
import { HDMapService } from '../../services/HDMapService';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';


export const HDMapListEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <div className="mb-2 text-end"><a href="/admin">Return to Admin</a></div>
        <GenericListEditor<HDMapView, {}>
        colSpan={4}
        filter={{showAll: true}}
        getKey={x => x.id.toString()}
        search={(filter, offset, pageSize) => HDMapService.search(offset, pageSize, true)}
        tableHead={<tr>
            <th>#</th>   
            <th>Community/Facility</th>
            <th>Drug Type</th>
            <th>Task Type</th>
            <th>Drug Formulation</th>
            <th>Active</th>  
            <th>Actions</th>
        </tr>}
        tableBodyRow={(item, index) => <tr key={item.id.toString()}>
            <td>{index + 1}</td>
            <td>{item.communityFacility}</td>
            <td>{item.drugGroup.groupName}</td>
            <td>{item.taskType}</td>
            <td>{item.drugFormulation}</td>
            <td>{item.isActive ? "Yes" : "No"}</td>
            <td> <NavLink tag={Link} to={`/hd-map-edit/${item.id}`} >edit</NavLink></td>
        </tr>}
        addNewRoute={"/hd-map-add"}
        />
    </>;
}