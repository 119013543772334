import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { TaskType } from '../models';


export class TaskTypeService extends ServiceBase {

    public static async save(dto: TaskType): Promise<Result<TaskType>> {

        var result = await this.requestJson<TaskType>({
            url: `api/TaskType/save`,
            method: "POST",
            data: dto
        });

        return result;
    }
   
    public static async delete(dto: TaskType): Promise<Result<TaskType>> {

        var result = await this.requestJson<TaskType>({
            url: `api/TaskType/delete`,
            method: "POST",
            data: dto
        });

        return result;
    }

    public static async get(id: number): Promise<Result<TaskType>> {

        var result = await this.requestJson<TaskType>({
            url: `api/TaskType/get/${id}`,
            method: "GET"
        });

        return result;
    }

    public static async search(offset: number, pageSize: number): Promise<Result<PagedResult<TaskType>>> {

        var result = await this.requestJson<PagedResult<TaskType>>({
            url: `api/TaskType/search`,
            method: "POST",
            data: { offset, pageSize }
        });

        return result;
    }

    public static async list(showAll: boolean): Promise<Result<TaskType[]>> {

        var result = await this.requestJson<TaskType[]>({
            url: `api/TaskType/list?showAll=${showAll}`,
            method: "GET"            
        });

        return result;
    }
}
