import { useReducer, useEffect, useRef } from 'react';
import GenericEditor from './GenericEditor';
import { DrugFormulationEditorFields } from '../editorFields/DrugFormulationEditorFields';
import { DrugFormulation, ValidationSchema } from '../../models/DrugFormulation';
import { DrugFormulationService }  from '../../services/DrugFormulationService';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';

export const DrugFormulationEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <GenericEditor<DrugFormulation, number>
        createNewModel={() => { return new DrugFormulation() }}
        getById={id => DrugFormulationService.get(id)}
        save={model => DrugFormulationService.save(model)}
        formikFields={DrugFormulationEditorFields}
        validationSchema={ValidationSchema}
        stringToId={x => parseInt(x, 10)}
        getId={x => x.id}
        shouldTryToLoad={id => id > 0}
        routes={{
            list: '/drug-formulations-list'
        }}
    />;
}