import { useReducer, useEffect, useRef } from 'react';
import GenericListEditor from './GenericListEditor';
import { Drug, ValidationSchema } from '../../models/Drug';
import { DrugService } from '../../services/DrugService';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { HDAdminService } from '../../services/HDAdminService';
import Cookies from 'js-cookie';

export const DrugListEditor = () => {

    const mountRef = useRef(true);

    const authUser = async () => {

        var authToken = Cookies.get("Authenticated");
        const result = await HDAdminService.auth(authToken);

        if (!result.hasErrors && mountRef.current) {
            if (!result.value.adminUserId) {
                window.location.href = '/admin';
            }
        }
    }

    // on component mount
    useEffect(() => {

        authUser();

        // keep track of mounted state
        return (() => {
            mountRef.current = false;
        })

    }, []);

    return <>
        <div className="mb-2 text-end"><a href="/admin">Return to Admin</a></div>
        <GenericListEditor<Drug, {}>
            colSpan={4}
            filter={{}}
            getKey={x => x.id.toString()}
            search={(filter, offset, pageSize) => DrugService.search(offset, pageSize)}
            tableHead={<tr>
                <th>#</th>
                <th>Drug Name</th>
                <th>Group</th>
                <th>Active</th>
                <th>Actions</th>
            </tr>}
            tableBodyRow={(item, index) => <tr key={item.id.toString()}>
                <td>{index + 1}</td>
                <td>{item.drugName}</td>
                <td><div className="d-flex">{item.drugGroup?.groupName} <div className={"ms-2 group-theme-bg-" + item.drugGroup.groupColor.toLowerCase()} style={{ height: '22px', width: '22px' }}></div></div></td>
                <td>{item.isActive ? "Yes" : "No"}</td>
                <th> <NavLink tag={Link} to={`/drugs-edit/${item.id}`} >edit</NavLink></th>
            </tr>}
            addNewRoute={"/drugs-add"}
        />
        </>;
}